import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { StorageService, storageKeys } from '@mca/shared/domain';

/**
 * redirects to last opened tab or to status tab if there's no scpecific tab in URL
 */
const verifyAccess = (routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const storageService = inject(StorageService);
  // child route activated for specific tab
  const parentMcaId = routeSnapshot.parent?.paramMap.get('mcaId');
  let tab = state.url.match(new RegExp(`${parentMcaId}/([^/]+)`))?.[1];
  const subMenu = state.url.match(new RegExp(`${parentMcaId}/${tab}/([^/]+)`))?.[1];
  if (parentMcaId) {
    storageService.set(storageKeys.mcaTab, { mcaId: parentMcaId, tab, subMenu });
    return true;
  }
  // mca page route activated with tab in url
  const mcaId = routeSnapshot.paramMap.get('mcaId');
  tab = state.url.match(new RegExp(`${mcaId}/([^/]+)`))?.[1];
  if (tab) {
    return true;
  }
  // mca page route without tab in url
  const data = storageService.get(storageKeys.mcaTab) ?? {};
  if (data.tab && data.mcaId === mcaId) {
    const urlParts = data.subMenu ? ['mcaedit', mcaId, data.tab, data.subMenu] : ['mcaedit', mcaId, data.tab];
    router.navigate(urlParts, { queryParamsHandling: 'preserve', preserveFragment: true });
    return false;
  }

  return router.createUrlTree(['mcaedit', mcaId, 'status']);
};

export const mcaTabGuardActivate: CanActivateFn = (routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return verifyAccess(routeSnapshot, state);
};
