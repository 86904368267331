/* eslint-disable @typescript-eslint/naming-convention */
import { MCAProgramTypes } from './mca-consts';

export const payOnDaysList = ['M-F (Excluding Holidays)', 'Weekly'];

export const billingFrequency = ['Daily', 'Weekly', 'Monthly'];

export const fundingTypeIds = {
  'Fixed Daily ACH': MCAProgramTypes.deal,
  'Consolidation ACH': MCAProgramTypes.consolidation,
  'Incremental Deal': MCAProgramTypes.incrementalDeal,
};
