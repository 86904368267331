import { LenderStatuses, MgmtFeeBases } from '@mca/shared/domain';
import { Person } from '@mca/user/api';

export class McaLender {
  id = 0;
  user: Person = new Person();
  partAmt = 0;
  part_pct = 0;
  rtr = 0;
  commisionpct = 0;
  commisionamt = 0;

  bypayment_fee_pct = 0;
  byPaymnetDefManagementFeeAmt = 0;
  upfront_fee_pct = 0;
  upfrontDefManagementFeeAmt = 0;
  mgmt_fee_base = MgmtFeeBases['Fund Amt'];

  totalAmt = 0;
  dailyAmt = 0;

  dailyMgmtFeePct = 0;
  dailyMgmtFeeAmt = 0;
  oneTimeMgmtFeePct = 0;
  oneTimeMgmtFeeAmt = 0;

  oneTimeAdminFeePct = 0;
  oneTimeAdminFeeAmt = 0;

  payments = 0;
  remPayments = 0;

  profit = 0;

  visible_docs = [];

  status = LenderStatuses.Setup;
}
