import { PermissionNames } from '@mca/auth/api';

const permissionsMap = {
  statusTabs: ['mca_status_w', 'mca_status_r', 'mca_balances_tab'],
  dba: ['mca_dba_w', 'mca_dba_r'],
  docs: ['mca_doc_w', 'mca_doc_r'],
  finance: ['mca_finance_w', 'mca_finance_r'],
  underwriting: ['mca_funding_w', 'mca_funding_r'],
  investorTabs: ['mca_payrol_w', 'mca_payrol_r', 'accounting_w'],
  transactions: ['mca_payment_w', 'mca_payment_r'],
  notes: ['mca_notes_w', 'mca_notes_r'],
  workflow: ['mca_workflow_w', 'mca_workflow_r'],
  events: ['mca_events_w', 'mca_events_r'],
  assignment: ['mca_assignment_w', 'mca_assignment_r'],
  performance: ['mca_performance_r'],
  datamerch: ['datamerch_r', 'datamerch_w'],
};

export const tabsPermissions = permissionsMap as Record<keyof typeof permissionsMap, PermissionNames[]>;
