import { Injectable, inject } from '@angular/core';
import { DBARec } from '@mca/shared/domain';
import { RxState } from '@rx-angular/state';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, filter, finalize } from 'rxjs';
import { McaRefCompany } from '../entities/mca-ref-company';
import { McaService } from '../infrastructure/mca.service';
import { MerchantPageService } from './merchant-page.service';
import { FormGroup } from '@angular/forms';
import { selectSlice } from '@rx-angular/state/selections';

export interface MerchantRefCompaniesState {
  merchant: DBARec;
  loading: boolean;
  refCompaniesDisabled: boolean;
  canWriteMerchant: boolean;
  companies: McaRefCompany[];
  validationContainer: FormGroup;
}

@Injectable()
export class MerchantRefCompaniesService extends RxState<MerchantRefCompaniesState> {
  private mcaService = inject(McaService);
  private messageService = inject(MessageService);
  private merchantPageService = inject(MerchantPageService);

  get merchant() {
    return this.get('merchant');
  }

  get companies() {
    return this.get('companies');
  }

  constructor() {
    super();
    this.set({
      merchant: new DBARec(),
      loading: false,
      companies: [],
      validationContainer: new FormGroup({}),
    });
    this.connect(this.merchantPageService.select().pipe(selectSlice(['merchant', 'refCompaniesDisabled', 'canWriteMerchant'])));
    this.hold(this.select('merchant'), () => this.load());
  }

  add() {
    this.set({ companies: [...this.companies, new McaRefCompany()] });
  }

  removeRec(rec: McaRefCompany) {
    const companies = [...this.companies];
    const idx = companies.indexOf(rec);
    companies.splice(idx, 1);
    if (!companies.length) {
      companies.push(new McaRefCompany());
    }
    this.set({ companies: [...companies] });
  }

  checkRequiredValues(companies: McaRefCompany[]) {
    const requiredFields: (keyof McaRefCompany)[] = ['name', 'taxid'];
    const requiredAddressFields: (keyof McaRefCompany)[] = ['streetaddress1', 'city', 'state', 'zip'];
    const companyMissingFields = companies.filter(company => requiredFields.some(field => !company[field]));
    const addressMissingFields = companies.filter(company => requiredAddressFields.some(field => !company[field]));

    if (companyMissingFields.length > 0 || addressMissingFields.length > 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Required fields missing',
        detail: 'You must fill out all mandatory fields (Name, Street, City, State, Zip and TAX ID)',
      });
      return false;
    }
    return true;
  }

  save(mcaid: number) {
    if (!this.checkRequiredValues(this.companies)) {
      return;
    }
    const validationContainer = this.get('validationContainer');
    if (validationContainer.invalid) {
      validationContainer.markAllAsTouched();
      this.messageService.add({ severity: 'error', detail: 'Please, correct or remove invalid records' });
      return;
    }
    this.set({ loading: true });
    this.mcaService
      .saveRefCompanies(mcaid, this.companies)
      .pipe(
        catchError(() => EMPTY),
        finalize(() => {
          this.set({ loading: false });
          this.merchantPageService.set({ refCompaniesDisabled: true });
        }),
      )
      .subscribe(companies => {
        this.set({ companies: companies.length ? companies : [new McaRefCompany()] });
        this.messageService.add({
          severity: 'success',
          detail: 'Reference Companies information updated',
        });
      });
  }

  load() {
    this.connect(
      'companies',
      this.mcaService.getRefCompanies(this.merchantPageService.mca.id).pipe(filter(companies => !!companies.length)),
    );
  }

  disableForm() {
    this.merchantPageService.set({ refCompaniesDisabled: true });
  }

  applySelectedMerchant(merchant: DBARec, fieldName: keyof DBARec, value: string, company: McaRefCompany) {
    for (const field of Object.keys(company)) {
      switch (field) {
        case 'name':
          company[field] = fieldName === 'legalName' ? value : merchant.legalName;
          break;
        case 'taxid':
          company[field] = fieldName === 'taxId' ? value : merchant.taxId;
          break;
        case 'streetaddress1':
          company[field] = merchant.address.streetLine1;
          break;
        case 'streetaddress2':
          company[field] = merchant.address.streetLine2;
          break;
        case 'city':
          company[field] = merchant.address.city;
          break;
        case 'state':
          company[field] = merchant.address.state;
          break;
        case 'zip':
          company[field] = +(merchant.address.zip ?? 0);
          break;
      }
    }
  }
}
