// partial list of statuses, used in conditions
export enum MCAStatuses {
  approved = 'Approved',
  lenderDebit = 'Lender Debit',
  funded = 'Funded',
  fundedNA = 'Funded NA',
  fundedEdit = 'Funded Edit',
  fundedOther = 'Funded-Other',
  declined = 'Declined',
  possibleDecline = 'Possible Decline',
  readyForFunding = '- READY FOR FUNDING',
  completed = 'Completed',
  contractIn = 'Contract IN',
  contractInExclusive = 'Contract in Exclusive',
  contractOut = 'Contract OUT',
  contractRequested = 'Contract Requested',
  canceled = 'Canceled',
  rescind = 'Rescind',
}

export enum MCAProgramTypes {
  deal = 1,
  consolidation = 2,
  incrementalDeal = 3,
}

export const programOptions = [
  { label: 'Fixed Daily ACH', value: MCAProgramTypes.deal },
  { label: 'Consolidation ACH', value: MCAProgramTypes.consolidation },
  { label: 'Incremental Deal', value: MCAProgramTypes.incrementalDeal },
];

export const McaProgramTitles = programOptions.reduce(
  (acc, option) => Object.assign(acc, { [option.value]: option.label }),
  {} as Record<MCAProgramTypes, string>,
);

export enum AssignmentTypes {
  User = 1,
  Role,
}
