export const httpMcaList = () => 'mca/list';
export const httpCreateMca = () => `mca/mca`;
export const httpMca = (id: number) => `mca/mca/${id}`;
export const httpMcaDub = (id: number) => `mca/dup/${id}`;
export const httpMcaDubWithOffers = (id: number) => `mca/dupwithoffers/${id}`;
export const httpMcaDupCheck = () => `mca/dupcheck`;
export const httpMcaRefreshBalance = (id: number) => `mca/refreshbalance/${id}`;
export const httpMcaRelated = (id: number) => `mca/related/${id}`;
export const httpMcaQA = (id: number) => `mca/qarecord/${id}`;
export const httpMcaFundingCallQA = (id: number) => `mca/${id}/answers`;
export const httpMcaReconciliation = (id: number) => `mca/${id}/collection/reconciliation`;
export const httpMcaReconciliationWorkflow = (id: number, workflowId: number) => `mca/${id}/collection/reconciliation/${workflowId}`;
export const httpMcaNotes = (id: number) => `mca/notes/${id}`;
export const httpMcaAudit = (id: number) => `mca/audit/${id}`;
export const httpMcaComponent = (id: number) => `mca/mcacomponent/${id}`;
export const httpMcaUpdComponent = (mcaid: number, component: string, id: number) => `mca/${mcaid}/${component}/${id}`;
export const httpMcaStatusStat = () => `mca/statusstat`;
export const httpMcaRateTermMap = () => `mca/reftab/ratetermmap`;
export const httpRequestRateChange = () => `pm/mcarate/request`;
export const httpMcaGenerateMerchantContract = (mcaId: number, offerId: number) => `mca/${mcaId}/generatemerchantcontract/${offerId}`;
export const httpMcaSubmitMerchantContract = (mcaId: number, offerId: number) => `mca/${mcaId}/submitmerchantcontract/${offerId}`;
export const httpMcaVoidDocuSignContract = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/contract/void`;
export const httpMcaSendReminder = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/contract/resend`;
export const httpMcaGenerateConsolidationGrid = (mid: number, offerId: number) => `mca/${mid}/generateconsolidationgrid/${offerId}`;
export const httpGenerateSubcontract = (mid: number, offerId: number) => `mca/${mid}/generatedisclosuresubcontract/${offerId}`;
export const httpMcaDoc = (id: number) => `mca/mcadoc/${id}`;
export const httpMcaDocUpdate = (mid: number, docid: number) => `mca/${mid}/mcadoc/${docid}`;
export const httpMcaTransStat = (id: number) => `mca/${id}/transstat`;
export const httpMcaWorkflow = (id: number) => `mca/${id}/workflow`;
export const httpMcaRefCompanies = (id: number) => `mca/${id}/mcarefcompanies`;
export const httpMcaCommSetUsers = (mcaId: number, setId: number) => `mca/${mcaId}/commissionset/${setId}`;
export const httpMcaCommUserSetActive = (mcaId: number, setId: number) => `mca/${mcaId}/commusersetactive/${setId}`;
export const httpMcaCommUserSendOffer = (mcaId: number, setId: number) => `mca/${mcaId}/commusersendoffer/${setId}`;
export const httpMcaLenderPartAgreement = (mcaId: number, agreement: number) => `mca/${mcaId}/lenderpartagreement/${agreement}`;
export const httpMcaGenerateCOJ = (mcaId: number, offerId: number) => `mca/${mcaId}/generatecoj/${offerId}`;
export const httpMcaGenerateAppendixA = (mcaId: number, offerId: number) => `mca/${mcaId}/generateattachmenta/${offerId}`;
export const httpMcaGenerateExhibitA = (mcaId: number, offerId: number) => `mca/${mcaId}/generateexhibita/${offerId}`;
export const httpMcaOffers = (mcaId: number, offerId?: number) => (offerId ? `mca/${mcaId}/offers/${offerId}` : `mca/${mcaId}/offers`);
export const httpIsoOfferVerify = (mcaId: number, offerId: number) => `users/iso/mca/${mcaId}/offers/${offerId}/verify`;
export const httpIsoOfferRequest = (mcaId: number, offerId: number) => `users/iso/mca/${mcaId}/offers/${offerId}`;
export const httpMcaOfferSend = (mcaId: number) => `mca/${mcaId}/offers/send`;
export const httpMcaOfferTemplate = () => 'offers/template';
export const httpMcaOfferTemplateId = (id: number) => `offers/template/${id}`;
export const httpMcaOfferExpire = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/expire`;
export const httpMcaOfferBatchExpire = (mcaId: number) => `mca/${mcaId}/offers/expire`;
export const httpMcaOfferCalcIncrementalTemplateFa = (mcaId: number) => `mca/${mcaId}/offers/incrementcalcfa`;
export const httpISOActiveOffers = (status: number) => `offers/user/${status}`;
export const httpGenerateBalanceLetter = (id: number) => `mca/${id}/balanceletter`;
export const httpGenerateContract = (id: number) => `mca/${id}/generatemerchantcontract`;
export const httpGeneratePaymentHistory = (id: number) => `mca/${id}/paymenthistory`;
export const httpGenerateDisclosureSubcontract = (id: number) => `mca/${id}/generatedisclosuresubcontract`;
export const httpGenerateExternalLawyerCollection = (id: number) => `mca/${id}/generateexternalcollection`;
export const httpGenerateACHPage = (id: number) => `mca/${id}/achpage`;
export const httpGenerateSettlementAgreement = (id: number) => `mca/${id}/generatesettlementagreement`;
export const httpExposure = (id: number) => `mca/${id}/exposure`;
export const httpPerformance = (id: number) => `mca/${id}/cashflowexpectedactual`;
export const httpMcaChangeStatus = (id: number) => `mca/${id}/status`;
export const httpMcaSetRFF = (mcaId: number, offerId: number) => `mca/${mcaId}/status/${offerId}/set-ready-for-funding`;
export const httpMcaNotifyIso = (id: number) => `mca/${id}/notify`;
export const httpMcaContractRequested = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/contract_requested`;
export const httpMcaSendContract = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/send_contract`;
export const httpMcaContractSigned = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/contract_signed`;
export const httpMcaContractSignedExclusive = (mcaId: number, offerId: number) =>
  `mca/${mcaId}/offers/${offerId}/contract_signed_exclusive`;
export const httpMcaRequestedContracts = () => `mca/offers/request_contract`;
export const httpMcaApproveContractRequest = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/contract_out`;
export const httpMcaContractToReadyFF = (mcaId: number) => `mca/${mcaId}/offers/ready_for_funding`;
export const httpMcaReadyFFContracts = () => `mca/offers/ready_for_funding`;
export const httpMcaApproveContractReadyFF = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/approve/ready_for_funding`;
export const httpMcaContractToFunding = (mcaId: number) => `mca/${mcaId}/offers/request_funding`;
export const httpMcaApproveContractFunding = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/approve/funding`;
export const httpMcaDeclineContractFunding = (mcaId: number, offerId: number) => `mca/${mcaId}/offers/${offerId}/decline/funding`;
export const httpMcaFundingContracts = () => `mca/offers/funding`;
export const httpMcaCalcOfferDataExposure = (mcaId: number) => `mca/${mcaId}/offers/calc_exposure`;
export const httpMcaFindRelated = (mcaId: number) => `mca/${mcaId}/related`;
export const httpMcaAllocationCalc = (transId: number) => `transaction/${transId}/allocation/calc`;
export const httpMcaViewContract = (id: number) => `mca/${id}/veiwcontract`;
export const httpLoadInvestorsParticipation = (id: number) => `mca/${id}/loadinvestorsparticipation`;
export const httpMcaAccountingBalances = (id: number) => `mca/${id}/accountingbalances`;
export const httpMcaAdjustBalance = (id: number) => `mca/${id}/adjust_balance`;
export const httpMcaDba = () => `mca/dba`;
export const httpMcaCollectionPaymentReminders = (mcaId: number) => `mca/${mcaId}/collection/reminder/active`;
export const httpMcaBankAccount = (mcaId: number, accountId?: number) =>
  accountId ? `mca/${mcaId}/bankaccount/${accountId}` : `mca/${mcaId}/bankaccount`;
export const httpMcaCreditCard = (mcaId: number, accountId?: number) =>
  accountId ? `mca/${mcaId}/creditcard/${accountId}` : `mca/${mcaId}/creditcard`;
export const httpMcaCreditCardCharge = () => `transaction/cc/send`;
export const httpMcaHasUCCFee = (taxId: string) => `mca/tax_id/${taxId}/has_ucc_fee`;

export const httpMcaCollatPortf = (id: number) => `mca/${id}/attributes/collatportf`;
export const httpMcaCollatPortfNames = () => `mca/attributes/collatportf/names`;

export const httpMcaDataMerch = (mcaId: number) => `mca/${mcaId}/datamerch`;
export const httpMcaDataMerchNote = (mcaId: number) => `mca/${mcaId}/datamerch/note`;
export const httpMcaDataMerchCategories = () => `mca/datamerch/categories`;
export const httpMcaAccountReceivableAssignment = (mcaId: number) => `mca/${mcaId}/ar/assignment`;
export const httpMcaAccountReceivableUserAssignment = (mcaId: number, userId: number) => `mca/${mcaId}/ar/${userId}/assignment`;
export const httpMcaAccountReceivableGenerateCoverLetter = (mcaId: number) => `mca/${mcaId}/generate_ar_cover_letter`;
export const httpMcaAccountReceivableSandCoverLetter = (mcaId: number) => `mca/${mcaId}/send_ar_cover_letter`;

export const httpMcaOcrolus = (mcaId: number) => `orcolus/${mcaId}`;

export const httpMcaIsoBonusEligible = (mcaId: number, offerId: number) => `mca/${mcaId}/bonus-eligible/${offerId}`;

export const httpMerchantBankruptcy = (subject: string, ein: string) => `${subject}/${ein}/bankruptcy`;
export const httpMerchantBankruptcyUpdate = (subject: string, ein: string, bankruptcyId: number) =>
  `${subject}/${ein}/bankruptcy/${bankruptcyId}`;
export const httpMerchantBankruptcyKeyDate = (subject: string, ein: string, bankruptcyId: number) =>
  `${subject}/${ein}/bankruptcy/${bankruptcyId}/key-date`;
export const httpMerchantBankruptcyKeyDateUpdate = (subject: string, ein: string, bankruptcyId: number, keyDateId: number) =>
  `${subject}/${ein}/bankruptcy/${bankruptcyId}/key-date/${keyDateId}`;
