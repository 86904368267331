import { Injectable, inject } from '@angular/core';
import { AuthService, PermissionsService, Roles } from '@mca/auth/api';
import { ReferencesService } from '@mca/references/api';
import { UserService } from '@mca/user/api';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { MessageService, SelectItem } from 'primeng/api';
import { catchError, filter, finalize, first, forkJoin, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { PayStrategy } from '../../entities/commisionuser';
import { MCAProgramTypes, MCAStatuses } from '../../entities/mca-consts';
import { commUserDispToDb, commUserRecToDisp, McaCommisionUserDisp, MCARec } from '../../entities/mcarec';
import { CommUserSet, McaOfferData, OfferTemplateCommissions } from '../../entities/offer';
import { McaService } from '../../infrastructure/mca.service';
import { McaPageService, McaPageState } from '../mca-page.service';
import { IsoUserMapService } from './iso-user-map.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
import { LazyService } from '@mca/shared/util';
import { McaScheduleService } from './schedule/mca-schedule.service';

export interface IsoCommissionsState {
  commUserSets: CommUserSet[];
  userList: SelectItem<number>[];
  isoUserList: SelectItem<number>[];
  isoUsers: number[];
  disabled: boolean;
  canOnlyViewOwnSet: boolean;
  canOnlyViewFundedOrActiveSet: boolean;
  canOnlyViewCommissions: boolean;
  userSelected: {
    [setId: number]: number;
  };
  preselectedUser: number;
  loading: boolean;
}

@Injectable()
export class IsoCommissionsService extends RxState<IsoCommissionsState> {
  private mcaService = inject(McaService);
  private mcaPageService = inject(McaPageService);
  private permissionsService = inject(PermissionsService);
  private userService = inject(UserService);
  private mcaScheduleService = inject(McaScheduleService);
  private messageService = inject(MessageService);
  private authService = inject(AuthService);
  private refService = inject(ReferencesService);
  private isoUserMapService = inject(IsoUserMapService);
  private dialogService = inject(DialogService);
  private lazyService = inject(LazyService);

  get mca() {
    return this.mcaPageService.get('mca');
  }

  constructor() {
    super();
    this.set({
      commUserSets: [],
      userList: [],
      isoUsers: [],
      canOnlyViewOwnSet: this.permissionsService.isIsoRel(),
      canOnlyViewFundedOrActiveSet: this.permissionsService.isIsoCollection(),
      canOnlyViewCommissions:
        this.permissionsService.hasPermission('offer_underwriting_form_iso') &&
        !this.permissionsService.hasPermission('offer_underwriting_form'),
      userSelected: {},
      preselectedUser: 0,
    });
    this.updateUsersInfo();
    this.hold(this.mcaPageService.getLoadedState().pipe(selectSlice(['mca', 'sets'])), state => this.updateDataFromMcaState(state));
  }

  selectUserInSet(setId: number, userId: number) {
    const userSelected = { ...this.get('userSelected') };
    userSelected[setId] = userId;
    this.set({ userSelected });
  }

  // preselected user - before set creation
  setPreselectedUser(preselectedUser: number) {
    this.set({ preselectedUser });
  }

  getUserSelected(setId: number) {
    return this.get('userSelected')[setId] ?? this.get('preselectedUser') ?? 0;
  }

  addSelectedUser(setId: number) {
    this.set({ loading: true });
    return this.addUser(setId, this.getUserSelected(setId), true).pipe(
      tap(() => this.selectUserInSet(setId, 0)),
      finalize(() => this.set({ loading: false })),
    );
  }

  getSetById(id: number) {
    return this.get('commUserSets').find(commSet => commSet.setindex === id) as CommUserSet;
  }

  selfInSet(userSet: CommUserSet) {
    const currentUser = this.authService.currentUser;
    return userSet.users.find(user => currentUser?.id === user.userid);
  }

  getViewableUserIds(commUserSets: CommUserSet[]) {
    let users = commUserSets.map(uSet => uSet.users).flat();
    const currentUser = this.authService.currentUser;
    if (currentUser && this.permissionsService.isIso(currentUser)) {
      users = users.filter(user => user.userid === currentUser.id);
    }
    if (currentUser && this.permissionsService.isIsoRel()) {
      users = users.filter(user => this.permissionsService.isIsoOrIsoRel(user));
    }
    return users.map(user => user.userid);
  }

  isActiveSet(userSet: CommUserSet): boolean {
    if (userSet.users.length === 0) {
      return false;
    }
    return !userSet.users.some(user => !user.active);
  }

  deleteCommUser(item: McaCommisionUserDisp) {
    this.mca.mcaCommisionUsers = this.mca.mcaCommisionUsers.filter(r => !(r.userid === item.userid && r.setindex === item.setindex));
    this.constructSets();
    this.sortSets();
  }

  confirmDeleteCommUser(item: McaCommisionUserDisp) {
    this.dialogService
      .confirm({
        title: 'Delete Confirmation',
        message: 'Do you want to remove user ' + item.dispName,
        styleClass: 'critical-confirm',
      })
      .subscribe(() => {
        this.dialogService.closeModal();
        this.deleteCommUser(item);
      });
  }

  addSet() {
    const commUserSets = [...this.get('commUserSets')];
    const idx = commUserSets.length === 0 ? 1 : commUserSets.map(i => i.setindex).reduce((accum, n) => Math.max(accum, n)) + 1;
    const defaultVenue = +(this.authService.systemConfig?.defaultVenue ?? 0);
    commUserSets.push(new CommUserSet(idx, defaultVenue, [], true));
    this.set({ commUserSets });
    const index$ = this.addSelectedUser(idx).pipe(map(() => idx));
    const venueCommissionUserIds$ = this.lazyService.loadOnce('venue-commission-users-' + defaultVenue, () =>
      this.userService.getVenueCommissionUsers(defaultVenue),
    );
    this.set({ loading: true });
    return forkJoin([index$, venueCommissionUserIds$]).pipe(
      switchMap(([setIndex, commissionUserIds]) =>
        forkJoin(commissionUserIds.map(userId => this.addUser(setIndex, userId, false))).pipe(
          map(() => setIndex),
          finalize(() => this.set({ loading: false })),
        ),
      ),
      tap(() => this.calcUserCom()),
    );
  }

  removeUserSet(userSet: CommUserSet) {
    if (!userSet.draft) {
      this.set({ loading: true });
      this.mcaService
        .commUserSetSaveUsers(this.mca.id, userSet.setindex, [])
        .pipe(finalize(() => this.set({ loading: false })))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'User set successfully removed',
          });
        });
    }
    const commUserSets = [...this.get('commUserSets')];
    const index = commUserSets.findIndex(v => v.setindex === userSet.setindex);
    if (index > -1) {
      commUserSets.splice(index, 1);
      this.set({ commUserSets });
    }
  }

  calcUserCom() {
    this.mcaPageService
      .getLoadedState()
      .pipe(first())
      .subscribe(state => {
        this.get('commUserSets').forEach(userSet => {
          const userSetTotals = this.getUserSetTotals(userSet.users, state);
          Object.assign(userSet, userSetTotals);
        });
        this.set({ commUserSets: [...this.get('commUserSets')] });
      });
  }

  getUserSetTotals(users: McaCommisionUserDisp[], mcaPageState: McaPageState) {
    const totals = {
      totalComm: 0,
      totalAmtCommisionUsers: 0,
      totalPctCommisionUsers: 0,
      cfAmount: 0,
      cfPct: 0,
    };
    users.forEach(r => {
      const fundedAmount = this.mcaScheduleService.getSchedule().offerForm.fundedAmt;
      let amount = 0;
      r.commisionpct = +r.commisionpct;
      if (r.baseindicator === mcaPageState.refConstants.amountbaseindicatorFA) {
        amount = fundedAmount;
      } else if (r.baseindicator === mcaPageState.refConstants.amountbaseindicatorRTR) {
        amount = +mcaPageState.mca.paybackAmount;
      } else if (r.baseindicator === mcaPageState.refConstants.amountbaseindicatorProfit) {
        amount = +mcaPageState.mca.totalProfitAmount;
      }
      r.commisionamt = Math.round(amount * r.commisionpct) / 100;
      r.cfAmount = Math.round(amount * r.contract_fee_allocation) / 100;
      r.totalComm = r.commisionamt + r.cfAmount;
      totals.totalAmtCommisionUsers += r.commisionamt;
      totals.totalPctCommisionUsers += r.commisionpct;
      totals.totalComm += r.totalComm;
      totals.cfAmount += r.cfAmount;
      totals.cfPct += r.contract_fee_allocation;
    });
    return totals;
  }

  save(userSet: CommUserSet) {
    const validationMessage = this.validateUserCommissions(userSet.setindex, this.mcaScheduleService.getSchedule().offerForm.fee);
    if (validationMessage) {
      this.messageService.add({ severity: 'error', summary: validationMessage });
      return;
    }
    this.set({ loading: true });
    userSet.users.forEach(u => (u.dealvenue = userSet.dealvenue));
    const users = this.mca.mcaCommisionUsers.filter(user => user.setindex === userSet.setindex).map(commUser => commUserDispToDb(commUser));
    this.mcaService
      .commUserSetSaveUsers(this.mca.id, userSet.setindex, users)
      .pipe(finalize(() => this.set({ loading: false })))
      .subscribe(result => {
        userSet.draft = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Users information updated',
          detail: result.message,
        });
        this.mcaPageService.refreshData();
      });
  }

  getSetTotalFee(setId: number) {
    return this.getSetById(setId).users.reduce((acc, user) => acc + +user.contract_fee_allocation, 0);
  }

  getSetTotalCommission(setId: number) {
    return this.getSetById(setId).users.reduce((acc, user) => acc + +user.commisionpct, 0);
  }

  getOfferCommissions(setId: number) {
    const commissionSet = this.getSetById(setId);
    return this.isoUserMapService.getOfferCommissions(commissionSet);
  }

  setOfferTemplateCommissionsOnUserSet$(setId: number, commissions: OfferTemplateCommissions) {
    const commissionSet = this.getSetById(setId);
    return this.isoUserMapService.setOfferTemplateCommissionsOnUserSet$(commissionSet, commissions);
  }

  validateUserCommissions(setId: number, contractFeePct: number) {
    const setFee = this.getSetTotalFee(setId);
    if (setFee > contractFeePct) {
      let message = `Contract fee in set ${setFee} is greater than total contract fee ${contractFeePct}`;
      if (!this.mca.position.funding_amount) {
        message += ' (check funding amount)';
      }
      return message;
    }
    return '';
  }

  updateUsersInfo() {
    this.userService.getCache().subscribe(userNames => {
      const userList = userNames
        .filter(user => this.permissionsService.isCommUser(user))
        .map((user: any) => ({ label: user.name, value: user.id }));
      const isoUserList = userNames
        .filter(user => this.permissionsService.isIso(user))
        .map((user: any) => ({ label: user.name, value: user.id }));
      this.set({
        userList: [{ label: '', value: 0 }, ...userList],
        isoUserList: [{ label: '', value: 0 }, ...isoUserList],
        isoUsers: userNames
          .filter(user => this.permissionsService.rolesAny(['role_iso', 'role_deactivated_iso'], user))
          .map(user => user.id),
      });
    });
  }

  canAddIso(): boolean {
    return this.permissionsService.hasPermission('mca_add_iso');
  }

  // name of user with iso role and with highest total commission
  getIsoName(setId: number) {
    const commissionSet = this.getSetById(setId);
    const isoRoleId = this.permissionsService.getRoleId(Roles.role_iso);
    const deactivatedIsoRoleId = this.permissionsService.getRoleId(Roles.role_deactivated_iso);
    return this.userService.getCachedMap().pipe(
      take(1),
      map(userMap => {
        const isoName = [...commissionSet.users]
          .sort((a, b) => Math.sign(b.totalComm - a.totalComm))
          .find(user => userMap[user.userid]?.roles.some(roleId => [deactivatedIsoRoleId, isoRoleId].includes(roleId)))?.dispName;
        return isoName ? `ISOSET${setId}: ${isoName}` : 'No ISO Users in Set';
      }),
    );
  }

  getUserSetOptions(
    withDeactivated = false,
    sort: (
      a: {
        user: McaCommisionUserDisp;
      },
      b: {
        user: McaCommisionUserDisp;
      },
    ) => number = () => 0,
  ): Observable<SelectItem[]> {
    const isoRoleIds = [this.permissionsService.getRoleId(Roles.role_iso)];
    if (withDeactivated) {
      isoRoleIds.push(this.permissionsService.getRoleId(Roles.role_deactivated_iso));
    }
    return this.userService.getCachedMap().pipe(
      take(1),
      map(userMap =>
        this.get('commUserSets')
          .filter(userSet => !userSet.draft)
          .map(userSet => {
            const isoUser = [...userSet.users]
              .sort((a, b) => Math.sign(b.totalComm - a.totalComm))
              .find(user => userMap[user.userid]?.roles.some(roleId => isoRoleIds.includes(roleId)));
            return isoUser
              ? ({
                  label: `ISOSET${userSet.setindex}: ${isoUser.dispName}`,
                  value: userSet.setindex,
                  user: isoUser,
                } as SelectItem)
              : null;
          })
          .filter(
            (
              v,
            ): v is {
              label: string;
              value: number;
              user: McaCommisionUserDisp;
            } => !!v,
          )
          .sort(sort),
      ),
    );
  }

  // find and print single pair: iso commission / iso relation commission
  getCommissionString(data: McaOfferData) {
    const commissionSet = this.getSetById(data.commissionset);
    return this.isoUserMapService.getCommissionString(commissionSet, data);
  }

  private addSponsors(setindex: number, sponsor: number): Observable<any> {
    if (sponsor === 0) {
      return of(null);
    }
    const currentSet = this.get('commUserSets').find(s => s.setindex === setindex);
    const inSetRecord = currentSet?.users.find(i => i.userid === sponsor);
    if (inSetRecord) {
      return of(null);
    }
    return this.addUser(setindex, sponsor, false);
  }

  addUser(setId: number, userId: number, withRelated: boolean) {
    if (!userId) {
      // new empty set is being created
      return of(null);
    }
    const userSet = this.getSetById(setId);
    if (userSet.users.findIndex(u => u.userid === userId && u.setindex === setId) > -1) {
      this.messageService.add({ severity: 'warn', summary: 'User is in set already' });
      return of(null);
    }

    return this.getLoadedCommUser(userId).pipe(
      switchMap(user => this.addUserToSet(user, setId, withRelated)),
      tap(() => {
        this.applyUserVenue(setId, userId);
        this.calcUserCom();
        this.updateUsersInfo();
        this.messageService.add({ severity: 'success', summary: 'User was added to the Set' });
      }),
      catchError(() => {
        console.error(`Cannot retrieve commission info for userId ${userId}`);
        return of(null);
      }),
    );
  }

  getLoadedCommUser(userId: number) {
    return this.lazyService.loadOnce('comm-user-info-' + userId, () =>
      this.userService.commissionUserInfo(userId).pipe(
        switchMap(user => {
          if (user === null) {
            throwError(() => userId);
          }
          return of(user);
        }),
      ),
    );
  }

  setVenue(setIndex: number, value: number) {
    const currentSet = this.get('commUserSets').find(s => s.setindex === setIndex);
    if (currentSet) {
      currentSet.dealvenue = value;
    }
    this.mca.mcaCommisionUsers.forEach(u => {
      if (u.setindex === setIndex) {
        u.dealvenue = value;
      }
    });
  }

  verifyCommissionStrategies(setIndex: number) {
    return this.isoUserMapService.verifyCommissionStrategies(this.getSetById(setIndex));
  }

  private applyUserVenue(setId: number, userId: number) {
    this.userService
      .getLogin(userId)
      .pipe(
        filter(user => this.permissionsService.isIso(user)),
        switchMap(() => this.userService.commissionUserInfo(userId)),
      )
      .subscribe(userInfo => {
        const isoVenueIsFundable = Object.keys(this.mcaPageService.get('fundingVenues')).some(venueId => +venueId === userInfo.dealvenue);
        if (isoVenueIsFundable) {
          this.setVenue(setId, userInfo.dealvenue);
        }
      });
  }

  private addUserToSet(user: any, setId: number, withRelated: boolean) {
    const commissionSet = this.getSetById(setId);

    const dispUser = this.dispUserFrom(user);
    dispUser.setindex = setId;
    dispUser.dealvenue = commissionSet.dealvenue;
    this.mca.mcaCommisionUsers.push(dispUser);

    commissionSet.users = [...commissionSet.users, dispUser];
    const request$ = withRelated ? this.addSponsors(setId, +user.relatedid) : of(null);
    return request$.pipe(
      tap(() => {
        const highestPctDealVenue = this.mca.mcaCommisionUsers
          .filter(u => u.setindex === setId && u.dealvenue)
          .reduce((acc, u) => (u.commisionpct > acc.commisionpct ? u : acc), dispUser)?.dealvenue;
        if (highestPctDealVenue) {
          commissionSet.dealvenue = highestPctDealVenue;
        }
      }),
    );
  }

  private dispUserFrom(user: any) {
    const du = new McaCommisionUserDisp();
    du.commisionpct =
      user.commisionpct ??
      (this.mcaScheduleService.getSchedule().offerForm.program === MCAProgramTypes.consolidation
        ? user.conscommissions
        : user.mcacommissions);
    du.paystrategy = user.comissionstrategy || PayStrategy.increment;
    du.userid = user.userid;
    du.baseindicator = 1;

    const dispUser = this.get('userList').find(u => u.value === user.userid);
    if (dispUser) {
      du.dispName = dispUser.label ?? '';
    }
    return du;
  }

  private updateDataFromMcaState(state: { mca: MCARec; sets: CommUserSet[] }) {
    const canWriteFunding = this.permissionsService.hasPermission('mca_funding_w');
    const fundedEnabled = canWriteFunding && state.mca.position.status !== this.refService.getStatusId(MCAStatuses.funded);
    this.set({ disabled: !fundedEnabled, commUserSets: [] });
    this.initCommUsers(state.sets);
    this.constructSets();
    this.sortSets();
  }

  private constructSets() {
    const commUserSets = [] as CommUserSet[];
    this.mca.mcaCommisionUsers.forEach(rec => {
      let userSet = commUserSets.find(s => s.setindex === rec.setindex);
      if (!userSet) {
        userSet = new CommUserSet(rec.setindex);
        userSet.dealvenue = rec.dealvenue;
        commUserSets.push(userSet);
      }
      userSet.users.push({ ...rec });
    });
    this.set({ commUserSets });
  }

  private initCommUsers(users: any[]) {
    if (!users || this.mca.mcaCommisionUsers) {
      return;
    }

    this.userService
      .getCachedMap()
      .pipe(first())
      .subscribe(
        userNameMap =>
          (this.mca.mcaCommisionUsers = users.map(user => {
            const userName = userNameMap[user.userid]?.name ?? user.userid;
            return commUserRecToDisp(user, userName, user.roles);
          })),
      );
  }

  private sortSets() {
    const userSets = this.get('commUserSets');
    userSets.sort((a, b) => {
      if (this.isActiveSet(a)) {
        return -1;
      }
      if (this.isActiveSet(b)) {
        return 1;
      }
      return 0;
    });
  }

  isOfferIsoCommissionEqualToSet(setId: number, offer: McaOfferData) {
    const commissionSet = this.getSetById(setId);
    return this.isoUserMapService.isOfferIsoCommisionEqualToSet(commissionSet, offer);
  }
}
