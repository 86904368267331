import { Injectable, inject } from '@angular/core';
import { HolidaysService } from '@mca/references/api';
import { OCPayment, PaymentFrequency } from '@mca/shared/domain';
import { McaScheduleState, McaScheduleType } from '../../../entities/mca-schedule';
import { McaSchedule } from './mca-schedule';
import { McaScheduleConsolidationService } from './mca-schedule-consolidation.service';
import { filter, switchMap, tap } from 'rxjs';
import { MCAProgramTypes } from '../../../entities/mca-consts';
import { roundMoney } from '@mca/shared/util';

@Injectable({
  providedIn: 'root',
})
export class McaScheduleIncrementalConsolidationService implements McaScheduleType {
  private holidaysService = inject(HolidaysService);
  private mcaScheduleConsolidation = inject(McaScheduleConsolidationService);

  fixedNumberOfDeposits = 0;
  private context!: McaSchedule;

  get consolidationService() {
    const service = this.mcaScheduleConsolidation.withContext(this.context);
    service.fixedNumberOfDeposits = this.fixedNumberOfDeposits;
    return service;
  }

  private get consolidationCF() {
    return this.context.state.additionalAmt < 0 ? this.context.state.feeAmt + this.context.state.additionalAmt : this.context.state.feeAmt;
  }

  private get totalDepositsAmt() {
    return this.context.offerForm.fundedAmt - this.consolidationCF;
  }

  withContext(context: McaSchedule) {
    this.context = context;
    return this;
  }

  calcDeposits() {
    this.context.updateOfferForm({ program: MCAProgramTypes.consolidation, incrementFrequency: PaymentFrequency.weekly });
    this.context.stateUpdate$
      .pipe(
        // direct call to calcDeposits of consolidation service won't do some updates, like recalculating additional amount
        tap(() => this.context.updateSchedule(true)),
        switchMap(() => this.context.stateUpdate$),
        tap(() => {
          this.context.updateState({
            offerForm: {
              program: MCAProgramTypes.incrementalDeal,
              numberOfIncrements: this.context.state.deposits.length,
            },
            programIncrements: this.calcProgramIncrements(),
          });
        }),
        // for fixed fee consolidation deposits should be kept as is
        filter(() => !this.context.offerForm.fixed_cf),
        switchMap(() => this.context.stateUpdate$),
      )
      .subscribe(() => {
        this.context.updateState({ deposits: this.calcDepositsFromProgramIncrements(this.context.state.programIncrements) });
      });
  }

  calcAdditionalAmount(params?: McaScheduleState) {
    return this.consolidationService.calcAdditionalAmount(params);
  }

  private calcProgramIncrements() {
    return this.context.state.deposits.map((deposit, i) => {
      const amount = i === 0 ? deposit.ammount - this.consolidationCF : deposit.ammount;
      return (amount / this.totalDepositsAmt) * 100;
    });
  }

  private calcDepositsFromProgramIncrements(schedule: number[]) {
    let incrDate = new Date(this.context.offerForm.depStartDate);
    const deposits = schedule.map((percent, i) => {
      incrDate = this.holidaysService.addBusinessDays(this.context.offerForm.depStartDate, this.context.offerForm.incrementFrequency * i);
      const payment = roundMoney(this.totalDepositsAmt * (percent / 100));
      return new OCPayment(incrDate, payment, false, 0);
    });
    this.setIncrementalFeesToDeposits(deposits);
    return this.roundToFundedAmt(deposits);
  }

  private setIncrementalFeesToDeposits(deposits: OCPayment[]) {
    deposits.forEach(deposit => {
      deposit.fee = roundMoney(this.context.state.feeAmt * (deposit.ammount / this.totalDepositsAmt));
      if (this.context.state.additionalAmt > 0) {
        deposit.ammount = roundMoney(deposit.ammount + deposit.fee);
      }
    });
  }

  private roundToFundedAmt(deposits: OCPayment[]) {
    const deltaFa = this.context.offerForm.fundedAmt - deposits.reduce((sum, deposit) => sum + deposit.ammount, 0);
    deposits[0].ammount = roundMoney(deposits[0].ammount + deltaFa);
    const deltaFees = this.context.state.feeAmt - deposits.reduce((sum, deposit) => sum + (deposit.fee ?? 0), 0);
    deposits[0].fee = roundMoney((deposits[0].fee ?? 0) + deltaFees);
    return deposits;
  }
}
