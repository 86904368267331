import { Injectable, inject } from '@angular/core';
import { PermissionsService } from '@mca/auth/api';
import { RxState } from '@rx-angular/state';
import { McaPageService } from '../mca-page.service';
import { map, take } from 'rxjs';
import { OfferStatus } from '@mca/shared/domain';
import { IsoCommissionsService } from './iso-commissions.service';

export interface OutstandingGroupState {
  canEdit: boolean;
  canViewForm: boolean;
  canViewFullForm: boolean;
  canViewIsoForm: boolean;
}

@Injectable()
export class OutstandingGroupService extends RxState<OutstandingGroupState> {
  private mcaPageService = inject(McaPageService);
  private permissionsService = inject(PermissionsService);
  private isoCommissionsService = inject(IsoCommissionsService);

  constructor() {
    super();
    this.set({
      canEdit: this.permissionsService.hasPermission('offer_underwriting_w'),
      canViewForm: !this.isoCommissionsService.get('canOnlyViewFundedOrActiveSet'),
    });
    this.connect(
      'canViewForm',
      this.mcaPageService.getLoadedState().pipe(
        take(1),
        map(
          state =>
            !(
              this.isoCommissionsService.get('canOnlyViewFundedOrActiveSet') &&
              state.offers.filter(offer => offer.status !== OfferStatus.Expired).length
            ),
        ),
      ),
    );
    this.connect(
      'canViewFullForm',
      this.select('canViewForm').pipe(map(canViewForm => canViewForm && this.permissionsService.hasPermission('offer_underwriting_form'))),
    );
    this.connect(
      'canViewIsoForm',
      this.select('canViewForm').pipe(
        map(canViewForm => canViewForm && this.permissionsService.hasPermission('offer_underwriting_form_iso')),
      ),
    );
  }
}
