import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { httpMcaCollatPortf, httpMcaCollatPortfNames } from './mca-http-points';

@Injectable({
  providedIn: 'root',
})
export class McaAttribServiceService {
  private apiService = inject(ApiService);

  setCollatoralProtf(mcaid: number, val: string) {
    return this.apiService.post(httpMcaCollatPortf(mcaid), { val });
  }

  getCollatoralProtf(mcaid: number) {
    return this.apiService.get<string>(httpMcaCollatPortf(mcaid));
  }

  listCollateralPortfNames() {
    return this.apiService.get<string[]>(httpMcaCollatPortfNames());
  }
}
