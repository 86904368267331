import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { MessageService } from 'primeng/api';
import { combineLatest, EMPTY, catchError, filter, map, switchMap, throwError, tap, forkJoin } from 'rxjs';
import { OfferStatus } from '@mca/shared/domain';
import { McaOffer, McaOfferData, OFFER_STATUSES_TO_PRELOAD, OfferTemplate } from '../../entities/offer';
import { OfferService } from '../../infrastructure/offer.service';
import { McaPageService } from '../mca-page.service';
import { IsoCommissionsService } from './iso-commissions.service';
import { OfferActionsService } from './offer-actions.service';
import { McaSchedule } from './schedule/mca-schedule';
import { McaScheduleService } from './schedule/mca-schedule.service';

interface McaOffersState {
  selectedOffers: {
    [setId: number]: number[];
  };
  offers: McaOffer[];
  offerTemplates: OfferTemplate[];
}

@Injectable()
export class McaOffersFeatureService extends RxState<McaOffersState> {
  private messageService = inject(MessageService);
  private mcaPageService = inject(McaPageService);
  private offerService = inject(OfferService);
  private mcaScheduleService = inject(McaScheduleService);
  private isoCommissionsService = inject(IsoCommissionsService);
  private offerActionsService = inject(OfferActionsService);
  private route = inject(ActivatedRoute);

  private get mca() {
    return this.mcaPageService.get('mca');
  }

  constructor() {
    super();
    this.set({ selectedOffers: {}, offerTemplates: [], offers: [] });
  }

  initState() {
    this.watchChanges();
    this.connect('offerTemplates', this.mcaPageService.select('offerTemplates'));
  }

  instantiateOffers(offerDarta: McaOfferData[]) {
    const offers = offerDarta.map(offer => Object.assign(new McaOffer(), offer));
    offers.sort((a, b) => {
      if (!McaOffer.isActive(b) && McaOffer.isActive(a)) {
        return -1;
      }
      if (McaOffer.isActive(b) && !McaOffer.isActive(a)) {
        return 1;
      }
      return new Date(b.name).getTime() - new Date(a.name).getTime();
    });
    let selectedId = +(this.route.snapshot.queryParamMap.get('offer') ?? 0);
    if (!selectedId) {
      selectedId = offers.filter(offer => OFFER_STATUSES_TO_PRELOAD.includes(offer.status)).sort((a, b) => b.status - a.status)[0]?.id ?? 0;
    }
    if (!this.get('offers').length && selectedId) {
      const selectedOffer = offers.find(v => v.id === selectedId) as McaOffer;
      this.setSelectedOffers(selectedOffer.commissionset, [selectedOffer]);
      this.offerActionsService.loadOffer(selectedOffer.commissionset, selectedId);
    }
    this.set({ offers });
  }

  getUpdatedOffer$(data: { setIndex: number; offerId?: number; schedule: McaSchedule }) {
    return data.schedule.getOfferData$(data.setIndex).pipe(
      tap(offer => {
        const userSet = this.isoCommissionsService.getSetById(data.setIndex);
        offer.commissionset = data.setIndex;
        offer.setCommSetData(userSet);
        offer.id = data.offerId ?? 0;
      }),
    );
  }

  getSelectedOffers(setId: number) {
    return this.get('selectedOffers')[setId] ?? [];
  }

  setSelectedOffers(setId: number, offers: McaOfferData[]) {
    const selectedOffers = this.get('selectedOffers');
    this.set({ selectedOffers: { ...selectedOffers, [setId]: offers.map(v => v.id) } });
  }

  getSetOffers(setIndex: number) {
    return this.select('offers').pipe(map(offers => offers.filter(offer => offer.commissionset === setIndex)));
  }

  isFundedOfferInSet(setIndex: number) {
    return this.get('offers')
      .filter(offer => offer.commissionset === setIndex)
      .some(offer => offer.status === OfferStatus.Funded);
  }

  applyOffer(offer: McaOffer) {
    try {
      this.applyOfferCommissionsToUserSet(offer);
    } catch (e: any) {
      this.messageService.add({ severity: 'error', summary: e.message });
      return null;
    }
    const mcaData = McaOffer.offerToMcaData(offer, this.mca);
    Object.assign(this.mca, { ...mcaData, position: { ...this.mca.position, ...mcaData.position } });
    this.isoCommissionsService.getOfferCommissions(offer.commissionset).subscribe(offerCommisions => {
      this.mcaScheduleService.applyOffers([offer], [offerCommisions]);
      this.messageService.add({
        severity: 'success',
        summary: 'Offer data applied',
      });
    });
    return mcaData;
  }

  private applyOfferCommissionsToUserSet(offer: McaOfferData) {
    const userSet = this.isoCommissionsService.getSetById(offer.commissionset);
    if (userSet?.users.length !== offer.commissions.length) {
      throw new Error('Offer users do not match set users');
    }
    offer.commissions.forEach(offerUser => {
      const user = userSet.users.find(u => u.userid === offerUser.userid);
      if (!user) {
        throw new Error('Offer users do not match set users');
      }
      const data = {
        id: offerUser.userid,
        commisionpct: offerUser.percent,
        baseindicator: offerUser.base_type,
        paystrategy: offerUser.paystrategy,
        dealvenue: offerUser.venueid,
        contract_fee_allocation: offerUser.contract_fee,
      };
      Object.assign(user, data);
    });
  }

  private watchChanges() {
    const mcaOffers$ = this.mcaPageService.getState().pipe(selectSlice(['offers']));
    const userSets$ = this.isoCommissionsService.select('commUserSets').pipe(filter(sets => !!sets.length));
    this.hold(
      // ensure that user sets are ready
      combineLatest([mcaOffers$, userSets$]),
      ([{ offers }]) => this.instantiateOffers(offers),
    );
    this.hold(this.mcaPageService.select('loadedOffers'), loadedOffers => {
      if (loadedOffers?.length === 1) {
        this.applyOffer(loadedOffers[0]);
        return;
      }
    });
  }

  saveOffer(setIndex: number, offerId?: number) {
    const offer = this.mcaPageService.getOffer(offerId ?? 0);
    this.mcaScheduleService.updateSchedules(true);
    let updatedOffers: McaOffer[] = [];
    this.mcaScheduleService.stateUpdateExhaust$
      .pipe(
        switchMap(() =>
          offer
            ? this.getUpdatedOffer$({ setIndex, offerId, schedule: this.mcaScheduleService.getScheduleByOffer(offer) }).pipe(map(o => [o]))
            : forkJoin(this.mcaScheduleService.schedules.map(schedule => this.getUpdatedOffer$({ setIndex, offerId, schedule }))),
        ),
        switchMap(offers => {
          updatedOffers = this.validateOffersUnique(offers);
          if (!updatedOffers.length) {
            return throwError(() => 'No new offers to save');
          }
          return offer
            ? this.offerService.updateOffer(this.mca.id, updatedOffers[0])
            : this.offerService.createOffer(this.mca.id, updatedOffers[0]);
        }),
        switchMap(response => this.mcaPageService.loadOffers$().pipe(map(() => response))),
        catchError(() => {
          this.mcaPageService.set({ offers: [...this.mcaPageService.get('offers')] });
          return EMPTY;
        }),
      )
      .subscribe((response: { offerid: number }) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Offer saved',
        });
        const responseOfferIds = [response.offerid];
        this.mcaPageService
          .get('offers')
          .filter(o => responseOfferIds.includes(o.id))
          .forEach((o, i) => {
            this.mcaScheduleService.getSchedule(i).updateState({ offerId: o.id });
          });
        const loadedOffers = updatedOffers.map((o, i) => Object.assign(new McaOffer(), { ...o, id: responseOfferIds[i] }));
        this.mcaPageService.set({ loadedOffers });
      });
  }

  createOffer(setIndex: number) {
    const validationMessages = this.mcaScheduleService.validate();
    for (const schedule of this.mcaScheduleService.schedules) {
      const commissionsMessage = this.isoCommissionsService.validateUserCommissions(setIndex, schedule.offerForm.fee);
      if (commissionsMessage.length > 0) {
        validationMessages.push(commissionsMessage);
      }
    }
    if (validationMessages.length) {
      this.isoCommissionsService.getIsoName(setIndex).subscribe(isoName => {
        this.messageService.add({
          severity: 'error',
          summary: `Cannot create offer for ${isoName}`,
          detail: validationMessages.join('\n'),
          life: 8000,
          contentStyleClass: 'pre-lined',
        });
      });
      return false;
    }
    this.saveOffer(setIndex);
    return true;
  }

  private validateOffersUnique(newOffers: McaOffer[]) {
    //// done within UBE-57, then got request to disable for now
    return newOffers;
    let result: McaOffer[] = newOffers;
    this.get('offers')
      .filter(o => o.commissionset === newOffers[0].commissionset)
      .forEach(o => {
        const equalCommission = this.isoCommissionsService.isOfferIsoCommissionEqualToSet(o.commissionset, o);
        result = result.filter(newO => {
          const equalProgram = o.funding_type === newO.funding_type;
          const quealAmount = o.amount === newO.amount;
          const equalRate = o.rates[0].rate === newO.rates[0].rate;
          const equalWithdrawals = o.withdrawal_freq === newO.withdrawal_freq && o.withdrawal_payment === newO.withdrawal_payment;
          const equalFee = o.contract_fee_pct === newO.contract_fee_pct;
          return !(equalCommission && equalProgram && quealAmount && equalRate && equalWithdrawals && equalFee);
        });
      });
    if (result.length !== newOffers.length) {
      this.messageService.add({ severity: 'warn', summary: 'Offer already exists' });
    }
    return result;
  }
}
