import { Injectable, InjectionToken, inject } from '@angular/core';
import { selectSlice } from '@rx-angular/state/selections';
import { RxState } from '@rx-angular/state';
import { MessageService, SelectItem } from 'primeng/api';
import { McaPageService } from './mca-page.service';
import { AuthService, PermissionsService, RoleNames, Roles } from '@mca/auth/api';
import { Observable, filter, finalize, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { BusinessEventService, ShouldApproveAssign, UserAssignmentEvent, UserAssignmentType } from '@mca/shared/domain';
import { UserReferenceService } from '@mca/user/api';
import { NonNullableFormBuilder } from '@angular/forms';
import { UserReference, UserService } from '@mca/user/domain';
import { ComponentType } from '@angular/cdk/portal';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FieldConfig, updateDynamicFormFields } from '@mca/shared/feature-dynamic-form';

type UserRoles = 'extLawyer' | 'extDebtStlmt' | 'collectionUser' | 'extCollectionUser';

interface AssignmentData {
  userId: string;
  userType: string;
  userRole: UserRoles | 'settlementUser';
}

const emptyUserEvent = {
  event_id: null,
  role: 0,
  user: '',
};

const userTypeMap = {
  extLawyer: 'ext_lawyer',
  extDebtStlmt: 'ext_debt_settlement_team',
  collectionUser: 'int_collector',
  extCollectionUser: 'ext_collector',
  settlementUser: 'settlement_agent',
};

type userType = (typeof userTypeMap)[keyof typeof userTypeMap];

interface AssignmentGroups {
  assign: Map<userType, AssignmentData>;
  unassign: Map<userType, AssignmentData>;
}

interface ServiceState {
  loading: boolean;
  initialized: boolean;
  extLawyerUserOptions: SelectItem[];
  extDebtStlmtUserOptions: SelectItem[];
  collectionUserOptions: SelectItem[];
  extCollectionUserOptions: SelectItem[];
  settlementUserOptions: SelectItem[];
  initialUserValues: Record<UserRoles | 'settlementUser', UserAssignmentEvent>;
  assignmentRoles: number[];
}

export const assignmentConfirmModalToken = new InjectionToken('assignment-confirm-modal');

export const assignmentConfirmModalFields: FieldConfig[] = [
  {
    name: 'details',
    type: 'component',
  },
];

@Injectable()
export class McaAssignmentService extends RxState<ServiceState> {
  private mcaPageService = inject(McaPageService);
  private permissionsService = inject(PermissionsService);
  private messageService = inject(MessageService);
  private userRefService = inject(UserReferenceService);
  private userService = inject(UserService);
  private authService = inject(AuthService);
  private fb = inject(NonNullableFormBuilder);
  private beService = inject(BusinessEventService);
  private dialogService = inject(DialogService);
  private assignmentConfirmModal = inject<ComponentType<any>>(assignmentConfirmModalToken);

  private get mca() {
    return this.mcaPageService.get('mca');
  }

  userValuesForm = this.fb.group({
    extLawyer: '',
    extDebtStlmt: '',
    collectionUser: '',
    extCollectionUser: '',
    settlementUser: '',
  });

  constructor() {
    super();
    this.set({
      loading: false,
      initialized: false,
      extLawyerUserOptions: [],
      extDebtStlmtUserOptions: [],
      collectionUserOptions: [],
      extCollectionUserOptions: [],
      assignmentRoles: [Roles.role_ext_lawyer, Roles.role_ext_debt_stlmt, Roles.role_ext_collection_agent, Roles.role_collection].map(
        role => this.permissionsService.getRoleId(role),
      ),
    });
  }

  init() {
    if (this.get('initialized')) {
      return;
    }
    this.set({ initialized: true });
    this.loadDropdownOptions();
    this.initUsersValue();
    this.watchValueChanges();
  }

  isMeAssigned(role: UserRoles | 'settlementUser') {
    return +this.get('initialUserValues')[role].user === this.authService.currentUser?.id;
  }

  assign$() {
    if (!this.mca.dbaRec.taxId) {
      this.messageService.add({ severity: 'error', summary: 'Deal Tax ID is required for assignment' });
      return;
    }
    const initialUserValues = this.get('initialUserValues');
    const userRoles = Object.keys(initialUserValues) as (keyof typeof initialUserValues)[];

    const assignmentGroups: AssignmentGroups = {
      assign: new Map<userType, AssignmentData>(),
      unassign: new Map<userType, AssignmentData>(),
    };

    userRoles.forEach(userRole => {
      this.processUserRole(userRole, assignmentGroups);
    });

    const requests$ = this.createBatchRequests(assignmentGroups);

    if (!requests$.length) {
      return;
    }

    return this.confirmAssignment(assignmentGroups).pipe(
      filter(Boolean),
      switchMap(() => this.approveAssignmentRequest(assignmentGroups)),
      filter(Boolean),
      switchMap(() => this.makeRequests(requests$)),
    );
  }

  private approveAssignmentRequest(assignmentGroups: AssignmentGroups) {
    if (assignmentGroups.assign.size === 0) {
      return of(true);
    }
    const users = [...assignmentGroups.assign].map(([userType, assignmentData]) => ({
      userid: Number(assignmentData.userId),
      user_type: userType,
    }));
    return this.beService.mcaShouldApproveAssignUsersByEin(this.mca.dbaRec.taxId, { users }).pipe(
      switchMap(response => {
        if (!response.length || !response.some(r => r.is_event_require)) {
          return of(true);
        }
        return this.showApprovementDialog(response).pipe(
          filter(Boolean),
          switchMap(() =>
            this.beService.mcaCreateAssignmentEventByEin(this.mca.dbaRec.taxId, { users }).pipe(
              tap(() => {
                this.messageService.add({ severity: 'success', summary: 'Assignment request sent' });
              }),
            ),
          ),
          map(() => false),
        );
      }),
    );
  }

  private confirmAssignment(assignmentGroups: AssignmentGroups) {
    const shouldConfirm = [...assignmentGroups.assign.values(), ...assignmentGroups.unassign.values()].length > 1;
    if (shouldConfirm) {
      return this.showConfirmationDialog(assignmentGroups);
    }
    return of(true);
  }

  private getAssignmentTable(assignmentGroups: AssignmentGroups) {
    return Object.entries(userTypeMap)
      .map(([userRole, userType]) => {
        const assignmentData = assignmentGroups.assign.get(userType);
        const unassignmentData = assignmentGroups.unassign.get(userType);

        const assignmentType = this.getRoleName(userRole as UserRoles | 'settlementUser');
        const previousAssignee = this.userService.userNameMap()[+this.get('initialUserValues')[userRole as UserRoles].user]?.name;
        const currentAssignee = assignmentData ? this.userService.userNameMap()[+assignmentData.userId].name : unassignmentData && '';

        return { assignmentType, previousAssignee, currentAssignee };
      })
      .filter(row => row.currentAssignee !== undefined);
  }

  private getRoleName(role: UserRoles | 'settlementUser') {
    const nameMap = {
      extLawyer: 'External Lawyer',
      extDebtStlmt: 'External Debt Settlement Team',
      collectionUser: 'Internal Collection Anget',
      extCollectionUser: 'External Collection Agent',
      settlementUser: 'Settlement Agent',
    };
    return nameMap[role];
  }

  private showConfirmationDialog(assignmentGroups: AssignmentGroups) {
    updateDynamicFormFields(assignmentConfirmModalFields, [
      {
        name: 'details',
        component: this.assignmentConfirmModal,
        componentInputs: { assignmentTable: this.getAssignmentTable(assignmentGroups) },
      },
    ]);
    return this.dialogService
      .open({
        title: 'Confirm Assignment',
        fields: assignmentConfirmModalFields,
        confirmLabel: 'Perform Assignment',
        cancelValue: false,
        cancelLabel: 'Cancel',
        styleClass: 'dialog__size-lg',
      })
      .pipe(
        finalize(() => this.dialogService.stopLoading()),
        tap(() => this.dialogService.closeModal()),
      );
  }

  private showApprovementDialog(response: ShouldApproveAssign[]) {
    return this.dialogService
      .open({
        title: 'Approval Request',
        fields: [
          ...response.map(r => ({
            name: 'message_' + r.mca_id,
            type: 'message',
            value: 'MCA# ' + r.mca_id + ': ' + (r.message || 'Approvement not required'),
            class: r.message ? 'text-dark' : 'text-black-50',
          })),
          {
            name: 'message_proceed',
            type: 'message',
            value: 'Do you wish to proceed?',
          },
        ] as FieldConfig[],
        confirmLabel: 'Perform Assignment',
        cancelValue: false,
        cancelLabel: 'Cancel',
      })
      .pipe(
        finalize(() => this.dialogService.stopLoading()),
        tap(() => this.dialogService.closeModal()),
      );
  }

  private makeRequests(requests$: Observable<unknown>[]) {
    this.set({ loading: true });
    return forkJoin(requests$).pipe(
      finalize(() => this.set({ loading: false })),
      tap(() => {
        this.messageService.add({ severity: 'success', summary: 'Assigned' });
        this.initUsersValue();
      }),
    );
  }

  private processUserRole(userRole: UserRoles | 'settlementUser', assignmentGroups: AssignmentGroups) {
    const userId = this.userValuesForm.controls[userRole].value;
    const userType = userTypeMap[userRole];
    if (userId === this.get('initialUserValues')[userRole].user) {
      return;
    }
    const groupKey = userId !== '' ? 'assign' : 'unassign';
    assignmentGroups[groupKey].set(userType, { userId, userType, userRole });
  }

  private createBatchRequests(assignmentGroups: AssignmentGroups): Observable<any>[] {
    const requests$ = [] as Observable<any>[];

    const einAssignUsers = [...assignmentGroups.assign].map(([userType, assignmentData]) => ({
      userid: Number(assignmentData.userId),
      user_type: userType,
    }));
    if (einAssignUsers.length > 0) {
      requests$.push(this.beService.mcaAssignUsersByEin(this.mca.dbaRec.taxId, { note: '', users: einAssignUsers }));
    }
    const einUnassignUsers = [...assignmentGroups.unassign].map(([userType]) => userType);
    if (einUnassignUsers.length > 0) {
      requests$.push(this.beService.mcaUnassignUsersByEin(this.mca.dbaRec.taxId, { user_types: einUnassignUsers }));
    }

    return requests$;
  }

  private watchValueChanges() {
    this.hold(this.select('initialUserValues'), initialUserValues => {
      this.userValuesForm.patchValue({
        extLawyer: initialUserValues.extLawyer.user,
        extDebtStlmt: initialUserValues.extDebtStlmt.user,
        collectionUser: initialUserValues.collectionUser.user,
        extCollectionUser: initialUserValues.extCollectionUser.user,
        settlementUser: initialUserValues.settlementUser.user,
      });
    });
  }

  private initUsersValue() {
    this.connect(
      'initialUserValues',
      this.beService.getAssignedUsers(this.mca.id, { roles: this.get('assignmentRoles') }).pipe(
        map(assignedUsers => ({
          extLawyer: this.getAssignedUserByType(assignedUsers, 'ext_lawyer'),
          extDebtStlmt: this.getAssignedUserByType(assignedUsers, 'ext_debt_settlement_team'),
          collectionUser: this.getAssignedUserByType(assignedUsers, 'int_collector'),
          extCollectionUser: this.getAssignedUserByType(assignedUsers, 'ext_collector'),
          settlementUser: this.getAssignedUserByType(assignedUsers, 'settlement_agent'),
        })),
      ),
    );
  }

  private getAssignedUserByType(assignedUsers: UserAssignmentEvent[], assignmentType: UserAssignmentType) {
    return (
      assignedUsers.reverse().find((user: UserAssignmentEvent) => user.user_type === assignmentType) || {
        ...emptyUserEvent,
      }
    );
  }

  private usersWithRoleToOptions(role: RoleNames, users: UserReference[]): SelectItem[] {
    return [
      { label: 'Not Assigned', value: '' },
      ...users
        .filter((user: any) => this.permissionsService.hasRole(role, user))
        .map((user: any) => ({ label: user.fullname, value: user.id })),
    ];
  }

  private loadDropdownOptions() {
    this.connect(
      this.userRefService.users$.pipe(
        map(users => ({
          extLawyerUserOptions: this.usersWithRoleToOptions('role_ext_lawyer', users),
          extDebtStlmtUserOptions: this.usersWithRoleToOptions('role_ext_debt_stlmt', users),
          collectionUserOptions: this.usersWithRoleToOptions('role_collection', users),
          extCollectionUserOptions: this.usersWithRoleToOptions('role_ext_collection_agent', users),
        })),
      ),
    );
    this.connect(
      'settlementUserOptions',
      this.select().pipe(
        selectSlice(['collectionUserOptions', 'extCollectionUserOptions', 'extLawyerUserOptions']),
        map(({ collectionUserOptions, extCollectionUserOptions, extLawyerUserOptions }) => {
          const result = [...extLawyerUserOptions];
          [collectionUserOptions, extCollectionUserOptions].forEach(options => {
            result.push(...options.filter(newOpt => !result.some(opt => opt.value === newOpt.value)));
          });
          return result;
        }),
      ),
    );
  }
}
