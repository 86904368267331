import { Injectable, inject } from '@angular/core';
import { AuthService } from '@mca/auth/api';
import { HolidaysService } from '@mca/references/api';
import { OCPayment, PaymentFrequency } from '@mca/shared/domain';
import { roundMoney } from '@mca/shared/util';
import { addDays, toDate } from 'date-fns';
import { McaScheduleState, McaScheduleType } from '../../../entities/mca-schedule';
import { McaSchedule } from './mca-schedule';

@Injectable({
  providedIn: 'root',
})
export class McaScheduleConsolidationService implements McaScheduleType {
  private holidaysService = inject(HolidaysService);
  private authService = inject(AuthService);

  // disables deposit squash for consolidate deal
  fixedNumberOfDeposits = 0;
  private context!: McaSchedule;

  get minDeposit() {
    return +(this.authService.systemConfig?.minDepositToMerchantAmount ?? 0);
  }

  get minLastDeposit() {
    return +(this.authService.systemConfig?.minDepositLastToMerchantAmount ?? 0);
  }

  withContext(context: McaSchedule) {
    this.context = context;
    return this;
  }

  calcDeposits() {
    const paymentsMap = this.getPaymentsMap();
    const deposits = this.getDepositSchedule(paymentsMap);
    const state = this.context.state;

    if (deposits.length > 0) {
      deposits[0].ammount += roundMoney(state.additionalAmt + state.feeAmt + state.buyOutAmt);
    } else {
      deposits.push(
        new OCPayment(this.context.state.offerForm.depStartDate, roundMoney(state.additionalAmt + state.feeAmt + state.buyOutAmt)),
      );
    }
    const totalDeposits = deposits.reduce((accum, node) => accum + (node.ammount + (node.fee ?? 0)), 0);
    const delta = this.context.offerForm.fundedAmt - totalDeposits;
    deposits[0].ammount += delta;

    this.context.updateState({ deposits: this.squashSmallDeposits(deposits) });
  }

  calcAdditionalAmount(params?: McaScheduleState) {
    const state = params ?? this.context.state;
    return roundMoney(state.offerForm.fundedAmt - state.feeAmt - state.totCurrentBalance);
  }

  private getPaymentsMap() {
    const paymentsMap = new Map<string, number>();
    this.context.mca.outstandingLoans.forEach(loan => {
      if (!loan.consolidate || !!loan.buyout) {
        return;
      }
      let depositDate = toDate(this.context.state.offerForm.depStartDate);
      if (!this.holidaysService.isBusinessDay(depositDate)) {
        depositDate = this.holidaysService.addBusinessDays(depositDate, 1);
      }
      let runningWithdrawAmount = +loan.balanceRemains;
      if (loan.payment < 0.01) {
        throw EvalError('Payment in outstanding loan ' + loan.name + ' is invalid');
      }
      let amount = roundMoney(+loan.payment);
      while (runningWithdrawAmount > 0) {
        const dateString = depositDate.toString();
        const currentValue = paymentsMap.has(dateString) ? paymentsMap.get(dateString) ?? 0 : 0;
        paymentsMap.set(dateString, amount + currentValue);
        runningWithdrawAmount -= amount;
        if (runningWithdrawAmount < amount) {
          amount = runningWithdrawAmount;
        }
        depositDate = this.holidaysService.addBusinessDays(depositDate, 1);
      }
    });
    return paymentsMap;
  }

  private getDepositSchedule(paymentsMap: Map<string, number>) {
    const deposits = [];
    let collectorPayment = new OCPayment();
    let dayCounter = 0;
    let startDDay = toDate(this.context.state.offerForm.depStartDate);
    if (!this.holidaysService.isBusinessDay(startDDay)) {
      startDDay = this.holidaysService.addBusinessDays(startDDay, 1);
    }

    paymentsMap.forEach((payment: number) => {
      // Week iterator - until hit Monday
      if (dayCounter === 0 && collectorPayment.ammount > 0) {
        deposits.push(collectorPayment);
        collectorPayment = new OCPayment();
        // For weekly we want to maintain same day unless weekend
        if (this.context.state.offerForm.selectedDepFreq === PaymentFrequency.weekly) {
          const currentDayDif = 7 * deposits.length;
          startDDay = toDate(this.context.state.offerForm.depStartDate);
          startDDay = addDays(startDDay, currentDayDif);
          if (!this.holidaysService.isBusinessDay(startDDay)) {
            startDDay = this.holidaysService.addBusinessDays(startDDay, 1);
          }
        } else {
          startDDay = this.holidaysService.addBusinessDays(startDDay, this.context.state.offerForm.selectedDepFreq);
        }
      }
      collectorPayment.ammount += payment;
      collectorPayment.effectivedate = toDate(startDDay);
      dayCounter += 1;
      if (dayCounter === this.context.state.offerForm.selectedDepFreq) {
        dayCounter = 0;
      }
    });
    if (collectorPayment.ammount > 0) {
      deposits.push(OCPayment.roundMoney(collectorPayment));
    }
    return deposits;
  }

  private squashSmallDeposits(deposits: OCPayment[]) {
    let smallDepositId = -1;
    const skippedDeposits = new Set();
    deposits.forEach((deposit, i) => {
      // you should update fee in small deposit if this condition will be changed in future
      deposit.fee = i === 0 ? this.context.state.feeAmt : 0;
      if (this.fixedNumberOfDeposits) {
        return;
      }
      if (smallDepositId > -1) {
        deposits[smallDepositId].ammount += deposit.ammount;
        skippedDeposits.add(i);
        smallDepositId = deposits[smallDepositId].ammount < this.minDeposit ? smallDepositId : -1;
        return;
      }
      if (deposit.ammount < this.minDeposit) {
        smallDepositId = i;
      }
    });
    const lastDepositIsSmall = deposits[deposits.length - 1].ammount < this.minLastDeposit;
    const previousNotSkippedId = (i: number) => {
      let result = i - 1;
      for (; skippedDeposits.has(result); result--) {}
      return result;
    };
    if (!this.fixedNumberOfDeposits && deposits.length > 1 && lastDepositIsSmall) {
      const lastDepositId = deposits.length - 1;
      const targetDepositId = smallDepositId > -1 && smallDepositId < lastDepositId ? smallDepositId : previousNotSkippedId(lastDepositId);
      deposits[targetDepositId].ammount += deposits[lastDepositId].ammount;
      skippedDeposits.add(deposits.length - 1);
    }
    return deposits.filter((deposit, index) => !skippedDeposits.has(index));
  }
}
