import { PermissionsService } from '@mca/auth/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FieldConfig } from '@mca/shared/feature-dynamic-form';
import { deepCopyIf, objectDiff, ObjectDiff } from '@mca/shared/util';
import { filter } from 'rxjs';
import { MerchantPageService } from './merchant-page.service';
import { DBARec } from '@mca/shared/domain';

export class MerchantApplyService {
  constructor(
    private context: MerchantPageService,
    protected permissionsService: PermissionsService,
    private dialogService: DialogService,
  ) {}

  applySelectedMerchant(fieldName: 'legalName' | 'taxId', selectedMerchant: DBARec, selectedValue: string) {
    this.context.set({ modalOpened: true });
    this.resolveFieldConflicts(fieldName, selectedMerchant, selectedValue);
    return;
    this.openActionDialog()
      .pipe(filter(Boolean))
      .subscribe(response => {
        if (response === 'apply') {
          this.resolveFieldConflicts(fieldName, selectedMerchant, selectedValue);
          return;
        }
        const merchant = deepCopyIf(selectedMerchant, {} as DBARec, (vFrom, vTo, field) => field !== 'mcaId');
        this.context.set({ merchant, modalOpened: false });
      });
  }

  private resolveFieldConflicts(fieldName: 'legalName' | 'taxId', selectedMerchant: DBARec, selectedValue: string) {
    (this.context.merchant[fieldName] as string) = selectedValue;
    const mergeEntries = Object.entries(objectDiff(this.context.merchant, selectedMerchant, true)).filter(
      ([key]) => ![fieldName, 'attributes', 'id'].includes(key),
    );
    if (!mergeEntries.length) {
      this.applyNonConflictingFields(selectedMerchant, this.context.merchant);
      this.context.set({ modalOpened: false });
      return;
    }
    this.context.set({ mergeEntries });
    this.context.openMergeDialog().subscribe(resolves => {
      const result = this.applyDiff(resolves, this.context.merchant);
      this.applyNonConflictingFields(selectedMerchant, result);
    });
  }

  private applyNonConflictingFields(from: DBARec, to: DBARec) {
    deepCopyIf(from, to, (vFrom, vTo, field) => !vTo && !!vFrom && !['id', 'mcaId'].includes(field as string));
    this.context.set({ merchant: to });
  }

  private applyDiff<T>(resolves: Record<string, 'a' | 'b'>, target: T): T {
    const mergeEntries = [...this.context.get('mergeEntries')];
    const result: Partial<T> = Object.entries(resolves).reduce((acc, [key, value]) => {
      // supports only 1 level of nesting
      const path = key.split('.');
      if (path.length > 1) {
        const nested = (target[path[0] as keyof T] ??= {} as any);
        const nestedEntires: [string, ObjectDiff][] = Object.entries(mergeEntries.find(([k]) => k === path[0])?.[1] ?? {});
        Object.assign(nested, { [path[1]]: nestedEntires.find(([k]) => k === path[1])?.[1][value] ?? '' });
        return Object.assign(acc, { [path[0]]: nested });
      }
      return Object.assign(acc, { [key]: mergeEntries.find(([k]) => k === key)?.[1][value] ?? '' });
    }, {} as Partial<T>);
    return { ...target, ...result } as T;
  }

  private openActionDialog() {
    return this.dialogService.open({
      title: 'Select Action',
      fields: [{ value: 'Choose what to do with selected record', type: 'message' } as FieldConfig],
      confirmLabel: '',
      cancelLabel: 'Cancel',
      extraButtons: [
        {
          label: 'Replace current record',
          type: 'submit',
          result: 'replace',
        },
        {
          label: 'Apply to form',
          type: 'submit',
          result: 'apply',
        },
      ],
    });
  }
}
