export class CredCardStatementRec {
  totalVol = Number(0);
  visaVol = Number(0);
  discoverVol = Number(0);
  amexVol = Number(0);
  cbItems = Number(0);
  chargebackVol = Number(0);
  items = Number(0);
  positiveItems = Number(0);
  batches = Number(0);
}

export class CredCardStatementRecDate {
  data: CredCardStatementRec;

  constructor(public month: string) {
    this.data = new CredCardStatementRec();
  }
}

export class CredCardStatementShit {
  recs: CredCardStatementRecDate[];
  total: CredCardStatementRec;
  average: CredCardStatementRec;

  constructor(public name: string, monthList: string[]) {
    this.recs = [];
    for (const i of monthList) {
      this.recs.push(new CredCardStatementRecDate(i));
    }
    this.total = new CredCardStatementRec();
    this.average = new CredCardStatementRec();
  }
}
