import { Injectable, OnDestroy, inject } from '@angular/core';
import { McaSchedule } from './mca-schedule';
import { HolidaysService } from '@mca/references/api';
import { McaPageService } from '../../mca-page.service';
import { McaService } from '../../../infrastructure/mca.service';
import { McaScheduleFixedService } from './mca-schedule-fixed.service';
import { McaScheduleIncrementalService } from './mca-schedule-incremental.service';
import { AuthService } from '@mca/auth/api';
import { McaScheduleConsolidationService } from './mca-schedule-consolidation.service';
import { MessageService } from 'primeng/api';
import { McaOffer, McaOfferData, OfferTemplateCommissions } from '../../../entities/offer';
import { McaScheduleState, ScheduleOfferForm } from '../../../entities/mca-schedule';
import { RxState } from '@rx-angular/state';
import { IsoUserMapService } from '../iso-user-map.service';
import {
  BehaviorSubject,
  debounce,
  debounceTime,
  expand,
  map,
  merge,
  switchMap,
  first,
  timer,
  delay,
  subscribeOn,
  asyncScheduler,
} from 'rxjs';
import { McaScheduleIncrementalConsolidationService } from './mca-schedule-incremental-consolidation.service';

@Injectable()
export class McaScheduleService extends RxState<never> implements OnDestroy {
  private holidaysService = inject(HolidaysService);
  private mcaPageService = inject(McaPageService);
  private messageService = inject(MessageService);
  private mcaService = inject(McaService);
  private mcaScheduleFixed = inject(McaScheduleFixedService);
  private mcaScheduleConsolidation = inject(McaScheduleConsolidationService);
  private mcaScheduleIncremental = inject(McaScheduleIncrementalService);
  private mcaScheduleConsolidationIncremental = inject(McaScheduleIncrementalConsolidationService);
  private isoUserMapService = inject(IsoUserMapService);
  private authService = inject(AuthService);
  private schedulesSubject = new BehaviorSubject([this.makeSchedule()]);

  get schedules() {
    return this.schedulesSubject.value;
  }
  get schedules$() {
    return this.schedulesSubject.asObservable();
  }

  // emits when all schedules reacted to form change
  calculated$ = this.schedules$.pipe(
    switchMap(schedules => merge(...schedules.map(s => s.offerForm$))),
    debounceTime(0),
  );
  // emits single time when there's no more pending state updates in all schedules
  get stateUpdateExhaust$() {
    const wait2EventLoopCycles$ = timer(0).pipe(delay(0));
    const update$ = merge(...this.schedules.map(s => s.state$)).pipe(
      debounce(() => timer(0)),
      first(),
    );
    return update$.pipe(
      // resubscribe after each update, async to avoid loop with same element
      expand(() => update$.pipe(subscribeOn(asyncScheduler))),
      debounce(() => wait2EventLoopCycles$),
      first(),
    );
  }

  state$ = this.schedules$.pipe(map(schedules => ({ schedules })));

  getSchedule(index?: number): McaSchedule {
    return this.schedules[index ?? 0];
  }

  // subscribes to changes of schedules array
  getSchedule$(index?: number) {
    return this.schedules$.pipe(map(schedules => schedules[index ?? 0]));
  }

  getScheduleByOffer(offer: McaOfferData) {
    return this.schedules.find(s => s.state.offerId === offer.id) ?? this.getSchedule();
  }

  addSchedule() {
    const newSchedule = this.makeSchedule();
    this.schedulesSubject.next([...this.schedules, newSchedule]);
  }

  removeSchedule(index: number) {
    const schedules = [...this.schedules];
    schedules.splice(index, 1);
    this.schedulesSubject.next(schedules);
  }

  validate() {
    return this.schedules.map(s => s.validate()).flat();
  }

  getTotal(field: keyof McaScheduleState) {
    return this.schedules.reduce((acc, s) => acc + Number(s.state[field]), 0);
  }

  getOffersTotal(field: keyof ScheduleOfferForm) {
    return this.schedules.reduce((acc, s) => acc + Number(s.offerForm[field]), 0);
  }

  updateSchedules(fixPayments = false) {
    this.schedules.forEach(s => s.updateSchedule(fixPayments));
  }

  applyOffers(offers: McaOffer[], offerCommisions?: OfferTemplateCommissions[]) {
    this.schedules.forEach(s => s.destroy$.next());
    const schedules = offers.map((offer, i) => {
      const newSchedule = this.makeSchedule();
      const mca = newSchedule.mca;
      const mcaData = McaOffer.offerToMcaData(offer, mca);
      Object.assign(mca, { ...mcaData, position: { ...mca.position, ...mcaData.position } });
      if (offerCommisions) {
        newSchedule.updateState({ commissions: offerCommisions[i] });
      }
      newSchedule.applyOffer(offer);
      return newSchedule;
    });
    this.schedulesSubject.next(schedules);
    this.stateUpdateExhaust$.subscribe(() => this.updateExposures());
  }

  updateExposures() {
    if (this.schedules.length === 1) {
      this.getSchedule().updateExposure();
      return;
    }
  }

  ngOnDestroy() {
    this.schedules.forEach(s => s.destroy$.next());
  }

  private makeSchedule() {
    return new McaSchedule(
      this.holidaysService,
      this.mcaPageService,
      this.messageService,
      this.mcaService,
      this.mcaScheduleFixed,
      this.mcaScheduleConsolidation,
      this.mcaScheduleIncremental,
      this.mcaScheduleConsolidationIncremental,
      this.isoUserMapService,
      this.authService,
    );
  }
}
