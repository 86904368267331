import { Injectable } from '@angular/core';
import { OCPayment } from '@mca/shared/domain';
import { roundMoney } from '@mca/shared/util';
import { McaScheduleType } from '../../../entities/mca-schedule';
import { McaSchedule } from './mca-schedule';

@Injectable({
  providedIn: 'root',
})
export class McaScheduleFixedService implements McaScheduleType {
  // not used for fixed deals
  fixedNumberOfDeposits = 0;
  private context!: McaSchedule;

  withContext(context: McaSchedule) {
    this.context = context;
    return this;
  }

  calcDeposits() {
    this.context.updateState({
      deposits: [
        new OCPayment(this.context.offerForm.depStartDate, roundMoney(this.context.offerForm.fundedAmt), false, this.context.state.feeAmt),
      ],
    });
  }

  calcAdditionalAmount() {
    return 0;
  }
}
