export const MerchantLabels = {
  id: 'ID',
  legalName: 'Legal/Corporate Name',
  dbaName: 'Merchant Name',
  industryClassification: 'Industry',
  yearsAtLocation: 'Years at Location',
  locationCount: '# Locations',
  stateOfIncorporation: 'State of Incorporation',
  typeOfBusinessEntity: 'Business Entity',
  phone: 'Office Phone #',
  fax: 'Fax #',
  email: 'Email Address',
  website: 'Website',
  taxId: 'Federal Tax ID #',
  contactName: 'Contact Name',
  businessStartDate: 'Business Start Date',
  streetLine1: 'Address Line 1',
  streetLine2: 'Address Line 2',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  county: 'County',
  country: 'Country',
};
