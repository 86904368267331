import { Injectable, inject } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { Table } from 'primeng/table';
import { catchError, of, tap } from 'rxjs';
import { McaService } from '../infrastructure/mca.service';
import { DBARec, MongoAddress } from '@mca/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class MerchantAutocompleteService {
  private mcaService = inject(McaService);

  foundMerchants = new Map<string, DBARec>();

  setupAutocompleteComponent(autocomplete: AutoComplete, initialValue = '') {
    autocomplete.search = () => {};
    if (initialValue) {
      autocomplete.value = initialValue;
      autocomplete.updateInputValue();
    }
  }

  selectMerchant(autocomplete: AutoComplete, fieldName: keyof DBARec) {
    const selectedLabel = this.getDbaLabel(autocomplete.value, autocomplete.value.mcaId);
    const selectedMerchant = this.foundMerchants.get(selectedLabel);
    if (!selectedMerchant) {
      return { selectedMerchant: null, selectedValue: '' };
    }
    const selectedValue = selectedMerchant[fieldName]?.toString() ?? '';
    this.setAutocompleteValue(autocomplete, selectedValue);
    return { selectedMerchant, selectedValue };
  }

  search(autocomplete: AutoComplete, fieldName: 'legalName' | 'taxId') {
    if (!autocomplete.value) {
      return;
    }
    autocomplete.loading = true;
    this.mcaService
      .getMcaDbaList({ [fieldName]: autocomplete.value })
      .pipe(
        tap(merchants =>
          merchants.forEach(m => {
            if (m.dbaRec.businessStartDate) {
              m.dbaRec.businessStartDate = new Date(m.dbaRec.businessStartDate);
            }
            if (!m.dbaRec.address) {
              m.dbaRec.address = new MongoAddress();
            }
          }),
        ),
        catchError(() => of([])),
      )
      .subscribe(merchantList => {
        this.foundMerchants = new Map(merchantList.map(v => [this.getDbaLabel(v.dbaRec, v._id), { ...v.dbaRec, mcaId: v._id }]));
        if (!merchantList.length) {
          autocomplete.suggestions = [];
          return;
        }
        autocomplete.inputEL?.nativeElement.focus();
        autocomplete.focused = true;
        autocomplete.suggestions = [...this.foundMerchants.values()];
      });
  }

  setupTableComponent(table: Table, autocomplete: AutoComplete) {
    // prevent leaving edit mode while autocomplete is looking for matches
    const isCellValid = table.isEditingCellValid;
    table.isEditingCellValid = () => {
      if (autocomplete.loading) {
        return false;
      }
      return isCellValid.bind(table)();
    };
  }

  private setAutocompleteValue(autocomplete: AutoComplete, value: string) {
    if (autocomplete.inputEL) {
      autocomplete.inputEL.nativeElement.value = value;
    }
    autocomplete.value = value;
    autocomplete.onModelChange(value);
    autocomplete.hide();
  }

  private getDbaLabel(dba: DBARec, mcaid: number) {
    return `${dba.taxId ?? ''} / ${dba.legalName ?? ''} / ${mcaid}`;
  }
}
