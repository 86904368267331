export interface McaAccountingBalances {
  crateBalance: number;
  currentBalance: number;
  spotBalance: number;
  spotCalacCommision: number;
  contractFundedAmt: number;
  sentFundedAmt: number;
  adjustedFundedAmt: number;
  totalPayments: number;
  achPayments: number;
  nonAchPayments: number;
  contractFee: number;
  adjustedContractFee: number;
  buyout: number;
  commissions: number;
  adjustedCommissions: number;
  sentCommissions: number;
  recalls: number;
  totalDiscounts: number;
  discounts: number;
  additionalDiscount: number;
  totalFees: number;
  feeWaiver: number;
  totalExpectedFees: number;
  fees: { name: string; value: number; expectedValue: number }[];
  contractRateContractRTR: number;
  contractRateEffectiveRTR: number;
  currentRateEffectiveRTR: number;
  spotRTR: number;
  spotContractFee: number;
  rtrBalance: number;
  refund: number;
  rateDescription: string;
  defaults: number;
  contraCF: number;
}

export const McaAccountingBalancesDeafults: McaAccountingBalances = {
  crateBalance: 0,
  currentBalance: 0,
  spotBalance: 0,
  spotCalacCommision: 0,
  contractFundedAmt: 0,
  adjustedFundedAmt: 0,
  sentFundedAmt: 0,
  totalPayments: 0,
  achPayments: 0,
  nonAchPayments: 0,
  contractFee: 0,
  adjustedContractFee: 0,
  buyout: 0,
  commissions: 0,
  adjustedCommissions: 0,
  sentCommissions: 0,
  recalls: 0,
  totalDiscounts: 0,
  discounts: 0,
  additionalDiscount: 0,
  totalFees: 0,
  feeWaiver: 0,
  totalExpectedFees: 0,
  fees: [{ name: '', value: 0, expectedValue: 0 }],
  contractRateContractRTR: 0,
  contractRateEffectiveRTR: 0,
  currentRateEffectiveRTR: 0,
  rtrBalance: 0,
  spotRTR: 0,
  spotContractFee: 0,
  refund: 0,
  rateDescription: '',
  defaults: 0,
  contraCF: 0,
};
