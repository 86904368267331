import { FormGroup } from '@angular/forms';
import { PermissionsService } from '@mca/auth/api';
import { DBARec, stateList } from '@mca/shared/domain';
import { ObjectDiff } from '@mca/shared/util';
import { RxState } from '@rx-angular/state';
import { SelectItem } from 'primeng/api';
import { MCARec } from '../entities/mcarec';
import { inject } from '@angular/core';

export interface MerchantPageState {
  mca: MCARec;
  merchant: DBARec;
  lastAppliedMerchant?: DBARec;
  loading: boolean;
  refCompaniesDisabled: boolean;
  canWriteMerchant: boolean;
  canWriteContacts: boolean;
  merchantFormDisabled: boolean;
  isSaveMerchantInProgress: boolean;
  modalOpened: boolean;
  validationContainer: FormGroup;
  businessTypeOptions: SelectItem<string>[];
  industryOptions: SelectItem<number>[];
  stateOptions: SelectItem<string>[];
  mergeEntries: {
    [K in keyof ObjectDiff]: [K, ObjectDiff[K]];
  }[keyof ObjectDiff][];
}

export class MerchantPageStateImpl extends RxState<MerchantPageState> {
  protected permissionsService = inject(PermissionsService);

  constructor() {
    super();
    this.set({
      refCompaniesDisabled: !this.permissionsService.hasPermission('mca_dba_w'),
      canWriteMerchant: this.permissionsService.hasPermission('mca_dba_w'),
      canWriteContacts: this.permissionsService.hasPermission('mca_contacts_w'),
      merchantFormDisabled: true,
      isSaveMerchantInProgress: false,
      merchant: new DBARec(),
      mca: new MCARec(),
      loading: false,
      modalOpened: false,
      validationContainer: new FormGroup({}),
      businessTypeOptions: [],
      industryOptions: [],
      stateOptions: [{ label: '', value: '' }, ...stateList.map(v => ({ label: v, value: v }))],
      mergeEntries: [],
    });
  }
}
