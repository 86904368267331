import { Injectable, inject } from '@angular/core';
import { AuthService } from '@mca/auth/api';
import { HolidaysService } from '@mca/references/api';
import { OCPayment } from '@mca/shared/domain';
import { roundMoney } from '@mca/shared/util';
import { McaScheduleType } from '../../../entities/mca-schedule';
import { McaSchedule } from './mca-schedule';

@Injectable({
  providedIn: 'root',
})
export class McaScheduleIncrementalService implements McaScheduleType {
  private holidaysService = inject(HolidaysService);
  private authService = inject(AuthService);

  fixedNumberOfDeposits = 0;
  private context!: McaSchedule;

  get minDeposit() {
    return +(this.authService.systemConfig?.minDepositToMerchantAmount ?? 0);
  }

  get minLastDeposit() {
    return +(this.authService.systemConfig?.minDepositLastToMerchantAmount ?? 0);
  }

  withContext(context: McaSchedule) {
    this.context = context;
    return this;
  }

  calcDeposits() {
    let numberOfDeposits = this.fixedNumberOfDeposits || this.context.offerForm.numberOfIncrements;
    if (numberOfDeposits === 0) {
      this.context.updateState({ deposits: [] });
      return;
    }
    let amount = roundMoney(this.context.offerForm.fundedAmt / numberOfDeposits);
    if (!this.fixedNumberOfDeposits && amount < this.minDeposit) {
      amount = this.minDeposit;
      numberOfDeposits = Math.ceil(this.context.offerForm.fundedAmt / amount);
    }
    let incrDate = new Date(this.context.offerForm.depStartDate);
    const deposits = [];
    for (let i = 1; i <= numberOfDeposits; i++) {
      deposits.push(new OCPayment(incrDate, amount, false, this.getIncrementFee(amount, i - 1)));
      incrDate = this.holidaysService.addBusinessDays(this.context.offerForm.depStartDate, this.context.offerForm.incrementFrequency * i);
    }
    this.tweakLastDeposit(deposits);
    this.context.updateState({ deposits });
  }

  calcAdditionalAmount() {
    return 0;
  }

  private getIncrementFee(amount: number, index: number) {
    if (this.context.offerForm.fixed_cf) {
      return index === 0 ? this.context.offerForm.fixed_cf : 0;
    }
    return this.context.state.feeAmt * (amount / this.context.offerForm.fundedAmt);
  }

  private tweakLastDeposit(deposits: OCPayment[]) {
    const amount = deposits[0].ammount;
    const sumDeposits = deposits.reduce((acc, deposit) => acc + deposit.ammount, 0);
    const lastDepositAmount = roundMoney(this.context.offerForm.fundedAmt - (sumDeposits - amount));
    if (!this.fixedNumberOfDeposits && lastDepositAmount < this.minLastDeposit) {
      deposits[deposits.length - 2].ammount += lastDepositAmount;
      deposits[deposits.length - 2].fee = (deposits[deposits.length - 2].fee ?? 0) + (deposits[deposits.length - 1].fee ?? 0);
      deposits.pop();
    } else {
      deposits[deposits.length - 1].ammount = lastDepositAmount;
    }
  }
}
