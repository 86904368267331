import { McaRateTypes } from '@mca/shared/domain';
import { WORK_DAYS_IN_MONTH, addWeekDays, roundMoney } from '@mca/shared/util';
import { SelectItem } from 'primeng/api';
import { MCADocsContractParams, MCARec } from '../entities/mcarec';
import { HolidaysService } from '@mca/references/api';

export type stmtMonthRec = { [name: string]: number } & { month: string; total: number; dtiRatio: number; dtiRatioWOffer: number };
interface StatementsByYear {
  [year: number]: stmtMonthRec[];
}

export const bankStatementVsOutstanding = (mca: MCARec, scheduleWithdrAmt: number) => {
  let payment = 0;
  let nonCons = 0;
  mca.outstandingLoans?.forEach(p => {
    payment += p.payment;
    if (!p.consolidate) {
      nonCons += p.payment;
    }
  });
  payment *= WORK_DAYS_IN_MONTH;
  const withdrAmt = scheduleWithdrAmt || mca.position.payment_amount || 0;
  const totPay = (nonCons + withdrAmt) * WORK_DAYS_IN_MONTH;
  return mca.bankStatementShits.reduce((acc, stmt) => {
    if (!acc[stmt.year]) {
      acc[stmt.year] = stmt.recs.map(
        r =>
          ({
            month: r.month,
            [stmt.name]: r.data.depositsAmt,
            total: r.data.depositsAmt,
            dtiRatio: r.data.depositsAmt && (payment / r.data.depositsAmt) * 100,
            dtiRatioWOffer: r.data.depositsAmt && (totPay / r.data.depositsAmt) * 100,
          } as stmtMonthRec),
      );
      return acc;
    }
    acc[stmt.year].forEach((m, i) => {
      m[stmt.name] = stmt.recs[i].data.depositsAmt;
      m.total += stmt.recs[i].data.depositsAmt;
    });
    acc[stmt.year].forEach(m => {
      m.dtiRatio = m.total && (payment / m.total) * 100;
      m.dtiRatioWOffer = m.total && (totPay / m.total) * 100;
    });
    return acc;
  }, {} as StatementsByYear);
};

export const hasDiscountRate = (mca: MCARec): boolean => !!mca.position.rates?.some(v => v.rate_type === McaRateTypes.Discount);
export const getCurrentRateValue = (mca: MCARec) => mca.position.rates?.find(({ current }) => current)?.rate || 0;

export const getCurrentRTR = (mca: MCARec): number => getCurrentRateValue(mca) * (mca.position.funding_amount ?? 0);

export const mcaToContractParams = (mca: MCARec, holidaysService: HolidaysService) => {
  const params = new MCADocsContractParams();
  const lenienceDays = 2;

  if (hasDiscountRate(mca)) {
    params.earlyPayment.amount = roundMoney((mca.position.funding_amount ?? 0) * mca.discountFactorRate);
    const days = Math.round(params.earlyPayment.amount / (mca.position.payment_amount ?? 1)) + lenienceDays;
    const earlyTermDate = addWeekDays(params.earlyPayment.payDate, days);
    params.earlyPayment.payDate = holidaysService.isBusinessDay(earlyTermDate)
      ? earlyTermDate
      : holidaysService.addBusinessDays(earlyTermDate, 1);
  }

  params.averageRev = getAverageThreeMonthRev(mca);
  params.depPct = params.averageRev && Math.round((((mca.position.payment_amount ?? 0) * WORK_DAYS_IN_MONTH) / params.averageRev) * 100);
  if (params.depPct < 15) {
    params.depPct = 15;
  }
  if (params.depPct > 25) {
    params.depPct = 25;
  }
  return params;
};

const getAverageThreeMonthRev = (mca: MCARec) => {
  const statementsByYear = bankStatementVsOutstanding(mca, 0);
  let threeMonthTotal = 0;
  let monthsLeft = 3;
  const currentMonth = new Date().toLocaleString('default', { month: 'short' });
  const stmts = Object.entries(statementsByYear)
    .sort(([aYear], [bYear]) => Math.sign(+aYear - +bYear))
    .map(([, months]) => months);
  stmts.forEach(yearMonths => {
    for (let i = yearMonths.length - 1; i >= 0 && monthsLeft > 0; i--) {
      if (!threeMonthTotal && currentMonth === yearMonths[i].month) {
        continue;
      }
      if (yearMonths[i].total !== 0) {
        monthsLeft--;
        threeMonthTotal += yearMonths[i].total;
      }
    }
  });
  return threeMonthTotal && roundMoney(threeMonthTotal / (3 - monthsLeft));
};

export const calcMaxIncrementalPayment = (mca: MCARec, maxRatePct: number) => {
  if (!mca.bankStatementShits.length) {
    return 0;
  }
  const nonCons = mca.outstandingLoans.filter(l => !l.consolidate).reduce((acc, l) => acc + l.payment, 0);
  const lastThreeMonthsPayments = mca.bankStatementShits
    .map(bs => bs.recs)
    .flat()
    .filter(month => !!month.data.grossDepositsAmt)
    .slice(-3)
    .map(month => ((maxRatePct / 100) * month.data.depositsAmt) / WORK_DAYS_IN_MONTH - nonCons);
  const averagePayment = lastThreeMonthsPayments.reduce((acc, p) => acc + p, 0) / lastThreeMonthsPayments.length;
  return averagePayment < 0 ? 0 : averagePayment;
};

export enum MCAPaymentStatus {
  none = 1,
  completed,
  paying,
  modified,
  modified_custom,
  non_paying,
  lawyer_paying,
  lawyer_non_paying,
  settlement,
  bankruptcy,
}

export const MCAPaymentStatuses = [
  { id: MCAPaymentStatus.none, name: '', property_name: 'mcapaymentstatus_none' },
  { id: MCAPaymentStatus.completed, name: 'Completed', property_name: 'mcapaymentstatus_completed' },
  { id: MCAPaymentStatus.paying, name: 'Paying', property_name: 'mcapaymentstatus_paying' },
  { id: MCAPaymentStatus.modified, name: 'Modified', property_name: 'mcapaymentstatus_modified' },
  { id: MCAPaymentStatus.modified_custom, name: 'Modified-Custom', property_name: 'mcapaymentstatus_modified_custom' },
  { id: MCAPaymentStatus.non_paying, name: 'Non-Paying', property_name: 'mcapaymentstatus_non_paying' },
  { id: MCAPaymentStatus.lawyer_paying, name: 'Lawyer-Paying', property_name: 'mcapaymentstatus_lawyer_paying' },
  { id: MCAPaymentStatus.lawyer_non_paying, name: 'Lawyer-Non-Paying', property_name: 'mcapaymentstatus_lawyer_non_paying' },
  { id: MCAPaymentStatus.settlement, name: 'Settlement', property_name: 'mcapaymentstatus_settlement' },
  { id: MCAPaymentStatus.bankruptcy, name: 'Bankruptcy', property_name: 'mcapaymentstatus_bankruptcy' },
];

export const MCAPaymentStatusOptions = MCAPaymentStatuses.map(({ id, name }) => ({ value: id, label: name }));

export const MCAPaymentMethods: SelectItem[] = [
  { value: 'ACH', label: 'ACH' },
  { value: 'WIRE', label: 'WIRE' },
  { value: 'CREDIT CARD', label: 'CREDIT CARD' },
  { value: 'ZELLE', label: 'ZELLE' },
  { value: 'PAYPAL', label: 'PAYPAL' },
  { value: 'CHECK', label: 'CHECK' },
  { value: 'VENMO', label: 'VENMO' },
  { value: 'INTERNAL TRANSFER', label: 'INTERNAL TRANSFER' },
];
